// =========================================================================================@@
// Last Updated Date: Apr 4, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link, useRouteMatch } from 'react-router-dom'
import MediumListItemRow from 'src/core/kits/Medium/components/ListItemRow'
import { Title } from 'src/core/kits/UI'

const CollectionListView = ({ items, itemsAreGrouped }) => {
   const match = useRouteMatch()

   return (
      <>
         {itemsAreGrouped && items.map(itemGrouping => (
            <View key={itemGrouping.name} marginBottom="30px">
               <View padding="5px 0px">
                  <Title size="md">
                     {itemGrouping.name}
                  </Title>
               </View>
               {itemGrouping.items?.map(i => (
                  <MediumListItemRow
                     key={i.id}
                     coverUrl={i.coverMedia?.file.thumbnailUrlW480}
                     dateLastUpdated={i.dateLastUpdated}
                     linkTo={i.gatherUrl}
                     metadataValues={i.metadataValues}
                     name={i.name}
                     tags={i.tags}
                  />
               ))}
            </View>
         ))}
         {!itemsAreGrouped && items.map(i => (
            <MediumListItemRow
               key={i.id}
               coverUrl={i.coverMedia?.file.thumbnailUrlW480}
               dateLastUpdated={i.dateLastUpdated}
               linkTo={i.gatherUrl}
               metadataValues={i.metadataValues}
               name={i.name}
               tags={i.tags}
            />
         ))}
         <Link to={`${match.url}/-/create`}>
            <View className="ui-paragraph-text" padding="10px 0px">
               + New
            </View>
         </Link>
      </>
   )
}

CollectionListView.propTypes = {
   items: PropTypes.array.isRequired
}

export default CollectionListView
