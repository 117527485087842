import React from 'react'
import { css } from 'emotion'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const TabMenuButton = ({ linkExact, linkTo, name }) => {
   const activeButtonStyle = css`
      background-color: var(--buttonActiveBackgroundColor);
      color: var(--buttonActiveTextColor) !important;
   `

   const Button = linkTo ? NavLink : 'div'
   const buttonProps = linkTo
      ? { to: linkTo, exact: linkExact, activeClassName: activeButtonStyle }
      : {}

   return (
      <Button
         {...buttonProps}
         className={css`
            &:hover {
               background-color: var(--buttonActiveBackgroundColor);
               color: var(--buttonActiveTextColor) !important;
            }
         `}
         style={{
            display: 'flex',
            alignItems: 'center',
            height: '32px',
            borderRadius: 'var(--buttonBorderRadius)',
            color: 'var(--buttonTextColor)',
            padding: '2px 12px 0px 12px',
            margin: '0px 2px'
         }}>
         {name}
      </Button>
   )
}

TabMenuButton.propTypes = {
   linkExact: PropTypes.bool,
   linkTo: PropTypes.string,
   name: PropTypes.string.isRequired
}

TabMenuButton.defaultProps = {
   linkExact: false,
   linkTo: undefined
}

export default TabMenuButton
