// =========================================================================================@@
// Last Updated Date: Feb 10, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import { useQuery } from '@apollo/client'
import { getDeletedMediumList as getDeletedMediumListGql }
   from 'src/core/graphql/queries/getDeletedMediumList.gql'

const useDeletedMediumList = (variables, options) => {
   const { data, error, fetchMore, loading, networkStatus, refetch } =
      useQuery(getDeletedMediumListGql, {
         variables,
         notifyOnNetworkStatusChange: false,
         ...options
      })

   return {
      deletedMediumList: data
         ? data.deletedMediumList
         : { items: [], listInfo: {} },
      error,
      fetchMore,
      loading,
      networkStatus,
      refetch
   }
}

export default useDeletedMediumList
