// =========================================================================================@@
// Last Updated Date: Mar 22, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Button, Form, NotificationManagerContext, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { useCreateInitiative, useOrganization } from 'src/core/graphql/hooks'

const MediumCreateInput = ({
   nameInputMaxLength,
   onCreate,
   metadataValues,
   parentInitiativeId
}) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const { organization } = useOrganization()
   const { createInitiative } = useCreateInitiative()
   const mediumType = organization.initiativeTypes.find(t => t.class === 'medium')

   const handleCreate = async (values, actions) => {
      try {
         const result = await createInitiative({
            ...values,
            metadataValues,
            parentInitiativeId,
            privacy: 'inherit',
            typeId: mediumType.id
         })

         if (onCreate) {
            onCreate(result.data.createInitiative)
         }
      } catch (err) {
         showNotification({
            message: err?.graphQLErrors?.map(e => e.message).join(', ') || err.message,
            title: 'Error',
            type: 'error'
         })
      } finally {
         actions.resetForm()
         actions.setSubmitting(false)
      }
   }

   return (
      <Formik
         initialValues={{ name: '' }}
         onSubmit={handleCreate}
         render={({ handleSubmit, isSubmitting }) => (
            <Form
               elementAppearance="plain"
               elementBackgroundColor="var(--formFieldBackgroundColor)"
               elementFocusBackgroundColor="#f5f5f5"
               onSubmit={handleSubmit}>
               <View display="flex" width="100%">
                  <View flex="1 1 auto">
                     <Input
                        maxLength={nameInputMaxLength}
                        name="name"
                        size="sm"
                        placeholder="Name"
                        required
                     />
                  </View>
                  <View flex="0 0 auto" display="flex" justifyContent="flex-end">
                     <Button
                        type="submit"
                        name="Save"
                        color="var(--formFieldBackgroundColor)"
                        size="sm"
                        textColor="var(--textColor)"
                        mode={isSubmitting ? 'loading' : 'normal'}
                     />
                  </View>
               </View>
            </Form>
         )}
      />
   )
}

MediumCreateInput.propTypes = {
   metadataValues: PropTypes.array,
   nameInputMaxLength: PropTypes.string,
   onCreate: PropTypes.func,
   parentInitiativeId: PropTypes.string
}

MediumCreateInput.defaultProps = {
   metadataValues: [],
   nameInputMaxLength: '120',
   onCreate: undefined,
   parentInitiativeId: undefined
}

export default MediumCreateInput
