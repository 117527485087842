import React from 'react'
import { View } from 'oio-react'

// Page user sees when App is just new and has no pages yet
const MediumStart = () => (
   <View
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%">
      <View paddingBottom="60px" textAlign="center">
         {'Press the "=" button to see App options'}
         <br />
         {'Press the "+" button to create the first page in this App'}
      </View>
   </View>
)

export default MediumStart
