import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import Forbidden from '../Forbidden'
import NotFound from '../NotFound'

// =======================================================
// Error Boundary
// =======================================================

export default class ErrorBoundary extends Component {
   static propTypes = {
      children: PropTypes.node.isRequired,
      height: PropTypes.string
   }

   static defaultProps = {
      height: 'auto'
   }

   state = {
      error: null
   }

   componentDidCatch(error, errorInfo) {
      Sentry.withScope((scope) => {
         Object.keys(errorInfo).forEach((key) => {
            scope.setExtra(key, errorInfo[key])
         })

         Sentry.addBreadcrumb({
            category: 'ui',
            message: 'Error Boundary',
            level: 'fatal'
         })

         Sentry.captureException(error)
      })

      // eslint-disable-next-line no-console
      console.log('Unhandled Error', error, errorInfo)
      this.setState({ error })
   }

   render() {
      const { error } = this.state

      // Useful for debugging
      // console.log(JSON.parse(JSON.stringify(error)))

      // If the only errors returned are NotFound errors
      if (!error?.networkError && error?.graphQLErrors?.every(err => err?.extensions?.exception?.name === 'NotFound')) {
         return <NotFound />
      }

      // If the only errors returned are Forbidden errors
      if (!error?.networkError && error?.graphQLErrors?.every(err => err?.extensions?.exception?.name === 'Forbidden')) {
         return <Forbidden message={error.graphQLErrors[0].message} />
      }

      const networkErrorMsg = error?.networkError?.result?.message

      // If there are one or more errors (that are not NotFound & Forbidden type)
      if (error) {
         return (
            <div
               style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  padding: '30px',
                  zIndex: '1',
                  height: this.props.height
               }}>
               <center>
                  <b>Sorry! An unexpected error has occurred.</b>
                  <br />
                  {'Error Message: '}
                  {networkErrorMsg || error.message}
                  <br />
                  <br />
                  <button type="button" onClick={() => Sentry.showReportDialog()}>
                     Report Feedback
                  </button>
                  <br />
                  <br />
                  <a href="/">Return to Home page</a>
               </center>
            </div>
         )
      }

      return this.props.children
   }
}
