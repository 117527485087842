// =========================================================================================@@
// Last Updated Date: Feb 18, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { Modal } from 'oio-react'
import PropTypes from 'prop-types'
import { useMetadataFieldList } from 'src/core/graphql/hooks'
import MetadataEditor from 'src/sites/kits/Metadata/apps/Editor'
import useMetadataFieldAdapter from 'src/sites/kits/Metadata/utils/fieldAdapter'

const MediumRules = ({
   id,
   modalOnCloseComplete,
   modalOnCloseTrigger,
   modalOpen
}) => {
   const metadataAdapter = useMetadataFieldAdapter({ sourceId: id, sourceType: 'parent' })
   const { metadataFieldList, loading, refetch } = useMetadataFieldList({
      sources: [{ id, type: 'parent' }]
   })

   if (loading) {
      return null
   }

   return (
      <Modal
         borderRadius="6px"
         width="100%[a] 420px[b] 90%[c] 900px[d] 1100px[e-f]"
         height="100%[a-b] 95%[c-f]"
         maxWidth="720px[c]"
         onCloseComplete={modalOnCloseComplete}
         onCloseTrigger={modalOnCloseTrigger}
         open={modalOpen}>
         <MetadataEditor
            adapter={metadataAdapter}
            blueprintMode
            metadata={metadataFieldList}
            onChange={refetch}
            onCloseButtonClick={modalOnCloseTrigger}
         />
      </Modal>
   )
}

MediumRules.propTypes = {
   id: PropTypes.string.isRequired,
   modalOnCloseComplete: PropTypes.func.isRequired,
   modalOnCloseTrigger: PropTypes.func.isRequired,
   modalOpen: PropTypes.bool.isRequired
}

export default MediumRules
