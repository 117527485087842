// =========================================================================================@@
// Last Updated Date: Apr 4, 2022
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import { useMemo } from 'react'
import { useCreateMetadataField, useUpdateMetadataField, useRemoveMetadataField }
   from 'src/core/graphql/hooks'

const handleFieldCreate = async (createMetadataField, input, source) => {
   await createMetadataField({ ...input, ...source })
}

const handleFieldUpdate = async (updateMetadataField, { id, ...input }) => {
   await updateMetadataField({ id }, input)
}

const handleFieldRemove = async (removeMetadataField, { id }) => {
   await removeMetadataField({ id })
}

export default ({ sourceId, sourceType, title }) => {
   const { createMetadataField } = useCreateMetadataField()
   const { updateMetadataField } = useUpdateMetadataField()
   const { removeMetadataField } = useRemoveMetadataField()

   return useMemo(() => {
      return {
         title: title ?? 'Prototyping Metadata',
         createField: handleFieldCreate.bind(null, createMetadataField, {
            sourceId,
            sourceType
         }),
         updateField: handleFieldUpdate.bind(null, updateMetadataField),
         removeField: handleFieldRemove.bind(null, removeMetadataField)
      }
   }, [])
}
