import React from 'react'
import PropTypes from 'prop-types'
import { DragDropContext } from 'react-beautiful-dnd'
import { useMoveInitiative, useUpdateInitiative } from 'src/core/graphql/hooks'

const MediumDNDContext = ({ children }) => {
   const { updateInitiative } = useUpdateInitiative()
   const { moveInitiative } = useMoveInitiative()

   const handleDragEnd = (result) => {
      const { combine, draggableId, destination, source } = result
      const initiativeId = draggableId
      const newIndex = destination?.index

      // Drop Medium on top of another Medium
      if (combine?.droppableId === 'medium-droppable') {
         moveInitiative(initiativeId, combine.draggableId)
      // Nest Medium inside another Medium
      } else if (source.droppableId !== destination.droppableId) {
         moveInitiative(initiativeId, destination.droppableId)
      // Reorder Mediums
      } else {
         updateInitiative(
            { id: initiativeId },
            { order: newIndex }
         )
      }
   }

   return (
      <DragDropContext onDragEnd={handleDragEnd}>
         {children}
      </DragDropContext>
   )
}

MediumDNDContext.propTypes = {
   children: PropTypes.node.isRequired
}

export default MediumDNDContext
