import React from 'react'
import PropTypes from 'prop-types'

const Title = ({ children, size }) => (
   <div className={`ui-title ${size}`}>
      {children}
   </div>
)

Title.propTypes = {
   children: PropTypes.node.isRequired,
   size: PropTypes.oneOf(['sm', 'md', 'lg'])
}

Title.defaultProps = {
   size: 'md'
}

export default Title
