// =========================================================================================@@
// Last Updated Date: Feb 10, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import { useMutation } from '@apollo/client'
import { restoreMediumFromSnapshot as restoreMediumFromSnapshotGql }
   from 'src/core/graphql/mutations/restoreMediumFromSnapshot.gql'

const useRestoreMediumFromSnapshot = (mutationHookOptions) => {
   const [restoreMediumFromSnapshot, { called, client, data, error, loading }] = useMutation(
      restoreMediumFromSnapshotGql,
      { refetchQueries: ['getInitiativeList'], ...mutationHookOptions }
   )

   const wrappedMutationFn = (variables, mutationFnOptions) => restoreMediumFromSnapshot({
      variables,
      ...mutationFnOptions
   })

   return {
      restoreMediumFromSnapshot: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useRestoreMediumFromSnapshot
