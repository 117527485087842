// =========================================================================================@@
// Last Updated Date: Feb 24, 2022
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { Link, useRouteMatch } from 'react-router-dom'
import { SearchIcon } from 'assets/icons'
import { useInitiativeList, useMe, useMediumList, useOrganization } from 'src/core/graphql/hooks'
import MediumCreateControl from 'src/core/kits/Medium/components/CreateControl'
import MediumMoreControl from 'src/core/kits/Medium/components/MoreControl'
import TitleEditable from 'src/core/kits/Medium/components/TitleEditable'
import { ListMenuButton, TabMenuButton } from 'src/core/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const MediumNav = ({ orientation }) => {
   const match = useRouteMatch()
   const { organization } = useOrganization()
   const mediumType = organization.initiativeTypes.find(t => t.class === 'medium')

   const { me } = useMe()
   const isPersonalMedium = organization.id === me?.personalSpaceId

   const { initiative: medium } = useContext(InitiativeHierarchyContext)
   const groupItemsByField = medium.metadataValues.find(f => f.key === 'groupItemsBy')?.value
   const itemsAreGrouped = groupItemsByField && Object.keys(groupItemsByField).length > 0
   const sortByField = medium.metadataValues.find(f => f.key === 'sortItemsBy')?.value
   const itemsAreSorted = sortByField && Object.keys(sortByField).length > 0
   const mediumListIsCurated = !itemsAreGrouped && !itemsAreSorted

   const { mediumList } = useMediumList({
      groupBy: groupItemsByField,
      parentInitiativeId: medium?.id,
      sortBy: sortByField
   }, { skip: !medium?.id || mediumListIsCurated })

   // TODO: Remove this once mediumList has more capabilities
   // We use this to allow curated list that is sortable #1127
   const { initiativeList: curatedMediumList } = useInitiativeList({
      typeIds: mediumType.id,
      parentInitiativeId: medium?.id,
      limit: 50,
      sortBy: 'curated'
   }, { skip: !medium?.id || !mediumListIsCurated })

   if (orientation === 'horizontal') {
      return (
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="100%">
            <View
               display="flex"
               alignItems="center"
               flex="0 0 auto"
               width="300px">
               <TitleEditable id={medium.id} name={medium.name} size="sm" />
            </View>
            <View
               display="flex"
               justifyContent="center"
               alignItems="center"
               flex="1 1 auto">
               {!mediumListIsCurated && mediumList.items.map(nestedMedium => (
                  <TabMenuButton
                     key={nestedMedium.id}
                     linkTo={nestedMedium.gatherUrl}
                     name={nestedMedium.name}
                  />
               ))}
               {mediumListIsCurated && curatedMediumList.items.map(nestedMedium => (
                  <TabMenuButton
                     key={nestedMedium.id}
                     linkTo={nestedMedium.gatherUrl}
                     name={nestedMedium.name}
                  />
               ))}
            </View>
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               width="300px"
               textAlign="right"
               marginLeft="10px">
               <MediumCreateControl iconSize="18px" />
            </View>
         </View>
      )
   }

   return (
      <View position="relative" width="100%" height="100%">
         <div
            style={{
               display: 'flex',
               justifyContent: 'space-between',
               alignItems: 'center',
               height: 'var(--toolbarHeight)',
               padding: '0px 15px',
               position: 'absolute',
               top: '5px',
               right: '0px'
            }}>
            <MediumMoreControl
               popoverAnchorOriginHorizontal="left"
               popoverAnchorOriginVertical="top"
            />
         </div>
         <View
            width="100%"
            height="calc(100% - var(--toolbarHeight))"
            padding="0px 15px"
            scroll="on">
            <View
               height="var(--appHeadingHeight)"
               paddingLeft="10px"
               paddingTop="var(--viewportMarginTop)"
            />
            <View
               marginTop="45px"
               padding="20px 20px 20px 10px">
               <TitleEditable id={medium.id} name={medium.name} size="sm" />
            </View>
            {isPersonalMedium && medium.ancestors.length === 0 && (
               <View marginBottom="10px">
                  <Link to={`${match.url}/-/search`}>
                     <View
                        display="flex"
                        alignItems="center"
                        height="var(--buttonHeight)"
                        padding="0px 10px"
                        borderRadius="6px"
                        opacity="0.7"
                        backgroundColor="var(--formFieldBackgroundColor)">
                        <View
                           display="flex"
                           alignItems="center"
                           paddingBottom="2px"
                           marginRight="10px"
                           opacity="0.5">
                           <SearchIcon width="12px" height="12px" color="var(--textColor)" />
                        </View>
                        <View marginTop="1px" opacity="0.4" style={{ color: 'var(--textColor)' }}>
                           Search
                        </View>
                     </View>
                  </Link>
               </View>
            )}
            {itemsAreGrouped && mediumList.items.map(nestedMediumGrouping => (
               <View key={nestedMediumGrouping.name} marginBottom="30px">
                  <View
                     display="flex"
                     alignItems="center"
                     padding="5px 10px"
                     style={{ fontWeight: '600' }}>
                     <View flex="0 0 auto">
                        {nestedMediumGrouping.name}
                     </View>
                     <View
                        flex="1 1 auto"
                        borderTop="1px solid var(--borderColor)"
                        minWidth="20px"
                        marginLeft="10px"
                     />
                  </View>
                  {nestedMediumGrouping.items?.map(nestedMedium => (
                     <ListMenuButton
                        key={nestedMedium.id}
                        linkTo={nestedMedium.gatherUrl}
                        name={nestedMedium.name}
                     />
                  ))}
               </View>
            ))}
            {itemsAreSorted && !itemsAreGrouped && mediumList.items.map(nestedMedium => (
               <ListMenuButton
                  key={nestedMedium.id}
                  linkTo={nestedMedium.gatherUrl}
                  name={nestedMedium.name}
               />
            ))}
            {mediumListIsCurated && (
               <Droppable droppableId="pages">
                  {(pageDroppableProvided, droppableProvidedSnapshot) => (
                     <div ref={pageDroppableProvided.innerRef}>
                        {curatedMediumList.items.map((nestedMedium, index) => (
                           <Draggable
                              key={nestedMedium.id}
                              draggableId={nestedMedium.id}
                              index={index}>
                              {(pageProvided, pageSnapshot) => (
                                 <div
                                    ref={pageProvided.innerRef}
                                    {...pageProvided.draggableProps}
                                    {...pageProvided.dragHandleProps}
                                    style={pageProvided.draggableProps.style}>
                                    <ListMenuButton
                                       key={nestedMedium.id}
                                       linkTo={nestedMedium.gatherUrl}
                                       name={nestedMedium.name}
                                    />
                                 </div>
                              )}
                           </Draggable>
                        ))}
                        {pageDroppableProvided.placeholder}
                     </div>
                  )}
               </Droppable>
            )}
         </View>
         <View
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width="100%"
            height="var(--toolbarHeight)"
            padding="0px 15px"
            style={{ gap: '20px' }}>
            <MediumCreateControl />
         </View>
      </View>
   )
}

MediumNav.propTypes = {
   orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired
}

export default MediumNav
