export const calcRenderedNotes = (newIndex, notes, numNotesRendered) => {
   const middlePos = Math.floor(numNotesRendered / 2)
   const shiftFactor = middlePos > newIndex
      ? middlePos - newIndex
      : 0

   const start = newIndex - middlePos + shiftFactor
   const end = start + numNotesRendered
   const result = notes.slice(start, end)
   return result
}

export const determineNoteIndex = (noteIds, noteId, useFacingPages) => {
   const noteIndex = noteIds.indexOf(noteId)
   if (noteIndex === -1) {
      return 0
   }

   if (useFacingPages && noteIndex % 2 === 1) {
      return noteIndex - 1
   }

   return noteIndex
}

export const prevIndex = (currentIndex, useFacingPages) => {
   if (useFacingPages) {
      if (currentIndex > 1) return currentIndex - 2
      if (currentIndex % 2 === 1) return currentIndex - 1
   } else if (currentIndex > 0) {
      return currentIndex - 1
   }

   return currentIndex
}

export const nextIndex = (currentIndex, numNotes, useFacingPages) => {
   if (useFacingPages) {
      if (currentIndex < numNotes - 2) return currentIndex + 2
      if (currentIndex % 2 === 1) return currentIndex + 1
   } else if (currentIndex < numNotes - 1) {
      return currentIndex + 1
   }

   return currentIndex
}
