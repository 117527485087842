// =========================================================================================@@
// Last Updated Date: Mar 22, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { Button, Modal, NotificationManagerContext, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useInitiativeList, useMoveInitiative, useOrganization } from 'src/core/graphql/hooks'
import ContentListSearchBox from 'src/sites/components/Settings/ContentListSearchBox'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const MediumMove = ({
   modalOnCloseComplete,
   modalOnCloseTrigger,
   modalOpen,
   modalWidth,
   onMove
}) => {
   const { destinationMediumId, destinationMediumName } = useParams()
   const [activeSearchValue, setActiveSearchValue] = useState('')
   const [destination, setDestination] = useState()
   const { showNotification } = useContext(NotificationManagerContext)

   const { organization } = useOrganization()
   const { moveInitiative, mutating } = useMoveInitiative()
   const { initiative } = useContext(InitiativeHierarchyContext)

   // Overide with URL params if available
   const mediumId = destinationMediumId || initiative.id
   const mediumName = destinationMediumName || initiative.name

   const initiativeTypeIds = organization.initiativeTypes
      .filter(t => ['medium'].includes(t.class))
      .map(t => t.id)

   const { initiativeList } = useInitiativeList({
      search: activeSearchValue,
      typeIds: initiativeTypeIds,
      limit: 100,
      sortBy: 'name',
      levels: 'all'
   })

   const handleMove = async (values) => {
      try {
         moveInitiative(mediumId, destination)

         if (onMove) {
            onMove()
         }
      } catch (err) {
         showNotification({
            message: err?.graphQLErrors?.map(e => e.message).join(', ') || err.message,
            title: 'Error',
            type: 'error'
         })
      }
   }

   return (
      <Modal
         borderRadius="6px"
         width={modalWidth}
         onCloseComplete={modalOnCloseComplete}
         onCloseTrigger={modalOnCloseTrigger}
         open={modalOpen}>
         <View padding="15px">
            <View>
               {`Move ${mediumName}`}
            </View>
            <ContentListSearchBox
               onSubmit={setActiveSearchValue}
            />
            <View width="100%" height="300px" scroll="on">
               <View
                  padding="10px"
                  backgroundColor={destination === 'root' && '#eee'}
                  onClick={() => setDestination('root')}>
                  Organization Root
               </View>
               {initiativeList.items.map(i => (
                  <View
                     key={i.id}
                     padding="10px"
                     backgroundColor={destination === i.id && '#eee'}
                     borderTop="1px solid #aaa"
                     onClick={() => setDestination(i.id)}>
                     <b>{i.name}</b>
                     <br />
                     {`${i.slug} - ${i.id}`}
                  </View>
               ))}
            </View>
            <View display="flex" width="100%" justifyContent="flex-end">
               <View
                  width="100%"
                  maxWidth="270px"
                  display="flex"
                  justifyContent="space-between">
                  <Button
                     onClick={modalOnCloseTrigger}
                     width="49%"
                     name="Cancel"
                     color="#fff"
                     size="md"
                     textColor="#666"
                  />
                  {destination && (
                     <Button
                        width="49%"
                        onClick={handleMove}
                        name="Move"
                        color="#000"
                        size="md"
                        textColor="#fff"
                        mode={mutating ? 'loading' : 'normal'}
                     />
                  )}
               </View>
            </View>
         </View>
      </Modal>
   )
}

MediumMove.propTypes = {
   modalOnCloseComplete: PropTypes.func.isRequired,
   modalOnCloseTrigger: PropTypes.func.isRequired,
   modalOpen: PropTypes.bool.isRequired,
   modalWidth: PropTypes.string,
   onMove: PropTypes.func
}

MediumMove.defaultProps = {
   modalWidth: '90%[a-b] 500px[c-f]',
   onMove: undefined
}

export default MediumMove
