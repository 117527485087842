import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { TOCMenu } from 'src/core/kits/TOC'
import { MediumDNDContext } from 'src/core/kits/Medium'
import { useGlobalState } from 'src/core/state'

const GlobalViewport = ({ children }) => {
   const { headingStyle, theme } = useGlobalState()
   const userAgent = window.navigator.userAgent.toLowerCase()
   const isElectron = userAgent.includes(' electron/')

   useEffect(() => {
      document.body.className = `theme-${theme} heading-style-${headingStyle}`
   }, [headingStyle, theme])

   return (
      <MediumDNDContext>
         <div className={`gather-viewport ${isElectron ? 'desktop-mode' : ''}`}>
            {isElectron && <div className="gather-viewport-titlebar" />}
            <div
               style={{
                  position: 'fixed',
                  top: '0px',
                  right: '0px',
                  bottom: '0px',
                  left: '0px',
                  overflow: 'hidden'
               }}>
               {children}
            </div>
            {isElectron && (
               <div
                  style={{
                     position: 'fixed',
                     right: '0px',
                     bottom: '0px',
                     left: '0px',
                     height: '0px',
                     overflow: 'hidden',
                     backgroundColor: 'var(--formFieldBackgroundColor)'
                  }}
               />
            )}
            <TOCMenu />
         </div>
      </MediumDNDContext>
   )
}

GlobalViewport.propTypes = {
   children: PropTypes.node.isRequired
}

export default GlobalViewport
