// =========================================================================================@@
// Last Updated Date: Feb 17, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Button, Form, Modal, NotificationManagerContext, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useUpdateInitiative } from 'src/core/graphql/hooks'
import MetadataBlock from 'src/core/kits/Medium/components/MetadataBlock'
import { useFileUploads } from 'src/sites/kits/Utils'
import { TokensInput } from 'src/sites/kits/Utils/ConnectedForm'
import ImageUpload from 'src/sites/kits/Utils/ImageUpload'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const MediumInfo = ({
   modalOnCloseComplete,
   modalOnCloseTrigger,
   modalOpen,
   modalWidth
}) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const { initiative: medium } = useContext(InitiativeHierarchyContext)
   const { updateInitiative } = useUpdateInitiative()
   const { uploadFile, uploadedFiles, uploadsInProgress, resetFileUploadState } =
      useFileUploads({ targetId: medium.id, targetType: 'initiative' })

   const coverMediaUrl = medium.coverMedia?.file.thumbnailUrlW480

   const handleUpdateInitiative = async (values, actions) => {
      if (uploadsInProgress.length > 0) {
         showNotification({
            message: 'Please wait until your files are done uploading',
            title: 'Error',
            type: 'error'
         })

         actions.setSubmitting(false)
         return
      }

      try {
         await updateInitiative({ id: medium?.id }, {
            ...uploadedFiles,
            ...values
         })

         showNotification({
            message: 'Saved successfully',
            title: 'Success',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error',
            type: 'error'
         })
      } finally {
         actions.setSubmitting(false)
         resetFileUploadState()
      }
   }

   return (
      <Modal
         borderRadius="6px"
         width={modalWidth}
         onCloseComplete={modalOnCloseComplete}
         onCloseTrigger={modalOnCloseTrigger}
         open={modalOpen}>
         <View padding="30px">
            {medium.name}
            <Formik
               initialValues={{
                  name: medium.name,
                  tags: medium.tags
               }}
               onSubmit={handleUpdateInitiative}
               render={({ handleSubmit, isSubmitting, setFieldValue }) => (
                  <Form
                     elementAppearance="plain"
                     elementBackgroundColor="#eee"
                     elementFocusBackgroundColor="#f1f1f1"
                     labelTextColor="#000"
                     labelTextSize="2.5"
                     labelTextTransform="none"
                     labelTextWeight="medium"
                     onSubmit={handleSubmit}>
                     <Button
                        mode={isSubmitting ? 'loading' : 'normal'}
                        width="100%"
                        padding="25px"
                        size="md"
                        textColor="#fff"
                        type="submit"
                        name="Save"
                     />
                     <ImageUpload
                        onFileChange={uploadFile}
                        aspectRatio={0.6}
                        name="coverMedia"
                        emptyMessage="No cover image set"
                        purpose="coverPhoto"
                        src={coverMediaUrl}
                     />
                     <br />
                     <br />
                     <b>Tags</b>
                     <TokensInput name="tags" />
                     <br />
                     <b>Date Created</b>
                     <br />
                     {medium.dateAdded}
                     <br />
                     <br />
                     <MetadataBlock />
                  </Form>
               )}
            />
         </View>
      </Modal>
   )
}

MediumInfo.propTypes = {
   modalOnCloseComplete: PropTypes.func.isRequired,
   modalOnCloseTrigger: PropTypes.func.isRequired,
   modalOpen: PropTypes.bool.isRequired,
   modalWidth: PropTypes.string
}

MediumInfo.defaultProps = {
   modalWidth: '90%[a-b] 500px[c-f]'
}

export default MediumInfo
