// =========================================================================================@@
// Last Updated Date: Mar 14, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import React, { useCallback } from 'react'
import { Modal, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useDeletedMediumList, useRestoreMediumFromSnapshot } from 'src/core/graphql/hooks'
import { Button } from 'src/core/kits/UI'

const Trash = ({
   modalOnCloseComplete,
   modalOnCloseTrigger,
   modalOpen
}) => {
   const { deletedMediumList } = useDeletedMediumList()
   const { restoreMediumFromSnapshot } = useRestoreMediumFromSnapshot()

   const handleRestoreMedium = useCallback(async (snapshotId) => {
      try {
         await restoreMediumFromSnapshot({ id: snapshotId })
         window.alert('This snapshot has been succesfully restored')
      } catch (err) {
         console.error(err) // eslint-disable-line no-console
         window.alert(err.message)
      }
   }, [restoreMediumFromSnapshot])

   return (
      <Modal
         width="900px"
         height="600px"
         onCloseComplete={modalOnCloseComplete}
         onCloseTrigger={modalOnCloseTrigger}
         open={modalOpen}>
         <View className="ui-modal" width="100%" height="100%">
            <View width="100%" height="100%" scroll="on">
               <b>Trash</b>
               <br />
               <br />
               {deletedMediumList.items.map(snapshot => (
                  <div key={snapshot.id} style={{ paddingBottom: '24px' }}>
                     {snapshot.medium.name}
                     <br />
                     {`Deleted ${snapshot.date}`}
                     <Button onClick={() => handleRestoreMedium(snapshot.id)}>
                        Restore
                     </Button>
                  </div>
               ))}
            </View>
         </View>
      </Modal>
   )
}

Trash.propTypes = {
   modalOnCloseComplete: PropTypes.func.isRequired,
   modalOnCloseTrigger: PropTypes.func.isRequired,
   modalOpen: PropTypes.bool.isRequired
}

export default Trash
