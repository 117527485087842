import React from 'react'
import PropTypes from 'prop-types'
import MediumCreateInput from 'src/core/kits/Medium/components/CreateInput'
import MediumListItemFeedRow from 'src/core/kits/Medium/components/ListItemFeedRow'

const CollectionFeedView = ({ items, parentInitiativeId }) => (
   <>
      <MediumCreateInput parentInitiativeId={parentInitiativeId} />
      {items.map(i => (
         <MediumListItemFeedRow
            key={i.id}
            coverUrl={i.coverMedia?.file.thumbnailUrlW480}
            dateLastUpdated={i.dateLastUpdated}
            linkTo={i.gatherUrl}
            name={i.name}
            tags={i.tags}
         />
      ))}
   </>
)

CollectionFeedView.propTypes = {
   items: PropTypes.array.isRequired,
   parentInitiativeId: PropTypes.string.isRequired
}

export default CollectionFeedView
