// =========================================================================================@@
// Last Updated Date: Feb 17, 2022
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import { useMutation } from '@apollo/client'
import { createMetadataField as createMetadataFieldGql }
   from 'src/core/graphql/mutations/createMetadataField.gql'

const useCreateMetadataField = (mutationHookOptions) => {
   const [createMetadataField, { called, client, data, error, loading }] =
      useMutation(createMetadataFieldGql, mutationHookOptions)

   const wrappedMutationFn = (input, mutationFnOptions) => createMetadataField({
      variables: { input },
      ...mutationFnOptions
   })

   return {
      createMetadataField: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useCreateMetadataField
