import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const MediumListItemCard = ({ coverUrl, dateLastUpdated, linkTo, name, tags }) => {
   return (
      <Link
         to={linkTo}
         style={{ color: 'var(--textColor)' }}>
         <View
            width="100%"
            paddingTop="60%"
            borderRadius="2px"
            backgroundColor="var(--formFieldBackgroundColor)"
            backgroundImage={coverUrl && `url(${coverUrl})`}
            backgroundSize="cover"
            backgroundPosition="center center"
            backgroundRepeat="no-repeat"
         />
         <View padding="15px 0" opacity="0.9">
            <div style={{ color: 'var(--titleColor)', fontWeight: '600' }}>{name}</div>
            <div style={{ color: 'var(--metaColor)' }}>{dateLastUpdated}</div>
            {tags.length > 0 && (
               <View
                  display="flex"
                  flexFlow="row wrap"
                  marginTop="10px"
                  style={{ gap: '5px', color: 'var(--textColor)' }}>
                  {tags.map(tag => (
                     <div key={tag} style={{ marginRight: '10px' }}>{tag}</div>
                  ))}
               </View>
            )}
         </View>
      </Link>
   )
}

MediumListItemCard.propTypes = {
   coverUrl: PropTypes.string,
   dateLastUpdated: PropTypes.string.isRequired,
   linkTo: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   tags: PropTypes.array
}

MediumListItemCard.defaultProps = {
   coverUrl: undefined,
   tags: []
}

export default MediumListItemCard
