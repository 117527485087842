// =========================================================================================@@
// Last Updated Date: Feb 21, 2022
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext } from 'react'
import { useMetadataFieldList, useUpdateInitiative } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import EditInPlaceInput from './EditInPlaceInput'
import LiveTokenInput from './LiveTokenInput'

const MetadataBlock = () => {
   const { initiative: medium } = useContext(InitiativeHierarchyContext)
   const { updateInitiative } = useUpdateInitiative()
   const { metadataFieldList, loading } = useMetadataFieldList({ initiativeId: medium.id })

   if (loading) {
      return null
   }

   // TODO: Using `no-cache` is necessary to prevent the Edit field from bouncing around like
   // crazy, but also prevents the cached initiative data from being updated
   // See: #1097
   const handleUpdateMetadataValue = async (metadataFieldId, newMetadataValue) => {
      await updateInitiative({ id: medium.id }, {
         metadataValues: [{ fieldId: metadataFieldId, value: newMetadataValue }]
      }, {
         fetchPolicy: 'no-cache'
      })
   }

   return (
      <>
         {metadataFieldList.map((metadataField) => {
            const defaultValue = medium.metadataValues
            .find(m => m.fieldId === metadataField.id)
            ?.value

            return (
               <div key={metadataField.id} style={{ display: 'flex', gap: '20px' }}>
                  <span>
                     <b>{metadataField.key}</b>
                     <i>{` (${metadataField.sourceType})`}</i>
                  </span>
                  {metadataField.type === 'token' && (
                     <LiveTokenInput
                        defaultValue={defaultValue}
                        onChange={(newValue) => {
                           handleUpdateMetadataValue(metadataField.id, newValue)
                        }}
                     />
                  )}
                  {metadataField.type !== 'token' && (
                     <EditInPlaceInput
                        defaultValue={defaultValue}
                        onDebouncedChange={(newValue) => {
                           handleUpdateMetadataValue(metadataField.id, newValue)
                        }}
                        placeholder={metadataField.type}
                     />
                  )}
               </div>
            )
         })}
      </>
   )
}

export default MetadataBlock
