import React, { useState } from 'react'
import fetch from 'cross-fetch'
import { css } from 'emotion'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, NotificationInline, Text, View } from 'oio-react'
import { Link, useHistory } from 'react-router-dom'
import AccountContainer from 'src/sites/kits/Account/components/Container'
import { auStates, caStates, inStates, gbStates } from 'config/constants/location/states'
import countries from 'config/constants/location/countries'
import { Checkbox, Input, Select } from 'src/sites/kits/Utils/ConnectedForm'
import { useJoinCommunity, useMe, useOrganization } from 'src/core/graphql/hooks'
import apiFetch from 'src/sites/kits/Utils/apiFetch'

const states = {
   AU: auStates,
   CA: caStates,
   IN: inStates,
   GB: gbStates
}

// =======================================================
// Register Component
// =======================================================

const Register = () => {
   const history = useHistory()
   const [stateOptions, setStateOptions] = useState([])
   const [showZip, setShowZip] = useState(true)
   const [error, setError] = useState(null)

   const { organization } = useOrganization()
   const { refetch: refetchMe } = useMe()
   const { joinCommunity } = useJoinCommunity()

   const highlightColor = organization.theme.highlightColor || '#000'

   const handleChangeCountry = (countryCode, setFormFieldValue) => {
      setFormFieldValue('countryCode', countryCode)
      setFormFieldValue('state', '')
      setFormFieldValue('zip', '')

      // AU, CA, GB, IN is selected
      if (['AU', 'CA', 'GB', 'IN'].includes(countryCode)) {
         setFormFieldValue('state', states[countryCode][0].value)
         setShowZip(false)
         setStateOptions(states[countryCode])
      // US is selected
      } else if (countryCode === 'US') {
         setShowZip(true)
         setStateOptions([])
      // Any other country selected
      } else {
         setShowZip(false)
         setStateOptions([])
      }
   }

   const handleRegister = async (values, actions) => {
      const {
         confirmPassword,
         countryCode,
         password,
         state,
         tmpGns3Purpose,
         tmpMarketingSubscribe,
         userEmail,
         userFirstName,
         userLastName,
         userOrganization,
         zip
      } = values

      if (password !== confirmPassword) {
         setError('Passwords must match')
         actions.setSubmitting(false)
         return
      }

      try {
         setError(null)
         await joinCommunity({
            tmpGns3Purpose,
            tmpMarketingSubscribe,
            userEmail,
            userFirstName,
            userLastName,
            userLocation: {
               countryCode,
               state,
               zip
            },
            userOrganization,
            userPassword: password
         })

         const response = await apiFetch('/auth/session', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({ email: userEmail, password })
         })

         if (response.ok) {
            fetch('/gns3/user-registration-hook', {
               headers: { 'Content-Type': 'application/json' },
               method: 'POST',
               body: JSON.stringify({
                  countryCode,
                  state,
                  tmpGns3Purpose,
                  tmpMarketingSubscribe,
                  userEmail,
                  userFirstName,
                  userLastName,
                  userOrganization,
                  zip
               })
            })

            refetchMe().then(() => {
               history.push('/')
            })
         } else {
            const result = await response.json()
            setError(result?.message || 'An unexpected error occurred')
         }
      } catch (err) {
         setError(err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <AccountContainer contentMaxWidth="540px" logoutActionMessage="create a new account">
         <Formik
            initialValues={{
               confirmPassword: '',
               countryCode: 'US',
               tmpGns3Purpose: 'education',
               password: '',
               state: '',
               tmpMarketingSubscribe: true,
               userEmail: '',
               userFirstName: '',
               userLastName: '',
               userOrganization: '',
               zip: ''
            }}
            onSubmit={handleRegister}
            render={({ handleSubmit, isSubmitting, setFieldValue }) => (
               <Form
                  elementAppearance="plain"
                  elementBackgroundColor="#eee"
                  elementFocusBackgroundColor="#f3f3f3"
                  onSubmit={handleSubmit}>
                  <Text size="9[a-d] 10[e-f]" weight="medium" color="#000">
                     Create an Account
                  </Text>
                  <View float="left" width="100%" height="12px" />
                  <Text size="3[a-d] 3[e-f]" color="#666">
                     {`Welcome to the ${organization.name} Community! An account is required to download the GNS3 Software and participate in the Community. To create an account, just fill in the fields below!`}
                  </Text>
                  <View float="left" width="100%" height="20px" />
                  {error && (
                     <NotificationInline
                        borderRadius="3px"
                        type="error"
                        title="Oops!"
                        message={error}
                     />
                  )}
                  <View float="left" width="100%" height="5px" />
                  <Grid columns="1[a-b] 2[c-f]" spacing="10px">
                     <GridCell>
                        <Input
                           type="text"
                           name="userFirstName"
                           placeholder="First Name"
                           required
                           size="md"
                        />
                     </GridCell>
                     <GridCell>
                        <Input
                           type="text"
                           name="userLastName"
                           placeholder="Last Name"
                           required
                           size="md"
                        />
                     </GridCell>
                     <GridCell>
                        <Input
                           type="text"
                           name="userEmail"
                           placeholder="Email"
                           required
                           size="md"
                        />
                     </GridCell>
                     <GridCell>
                        <Input
                           type="text"
                           name="userOrganization"
                           placeholder="School/Organization"
                           required
                           size="md"
                        />
                     </GridCell>
                     <GridCell>
                        <Input
                           type="password"
                           name="password"
                           placeholder="Password"
                           required
                           size="md"
                        />
                     </GridCell>
                     <GridCell>
                        <Input
                           type="password"
                           name="confirmPassword"
                           placeholder="Confirm Password"
                           required
                           size="md"
                        />
                     </GridCell>
                     <GridCell colspan={(stateOptions.length || showZip) ? '1' : '1[a-b] 2[c-f]'}>
                        <Select
                           name="countryCode"
                           size="md"
                           onChange={(event) => {
                              handleChangeCountry(event.currentTarget.value, setFieldValue)
                           }}>
                           {Object.keys(countries).map(country => (
                              <option key={country} value={country}>
                                 {countries[country]}
                              </option>
                           ))}
                        </Select>
                     </GridCell>
                     {stateOptions.length > 0 && (
                        <GridCell>
                           <Select name="state" size="md">
                              {stateOptions.map(state => (
                                 <option key={state.value} value={state.value}>
                                    {state.name}
                                 </option>
                              ))}
                           </Select>
                        </GridCell>
                     )}
                     {showZip && (
                        <GridCell>
                           <Input size="md" name="zip" placeholder="Zip Code" />
                        </GridCell>
                     )}
                     <GridCell>
                        <View
                           display="flex"
                           justifyContent="flex-end"
                           alignItems="center"
                           width="100%"
                           height="100%"
                           padding="0px 10px">
                           <Text size="1.5" color="#666">
                              I use GNS3 Software for:
                           </Text>
                        </View>
                     </GridCell>
                     <GridCell>
                        <Select name="tmpGns3Purpose" size="md">
                           <option value="education">Education & Training</option>
                           <option value="work">Work</option>
                           <option value="education-and-work">Learning & Work</option>
                        </Select>
                     </GridCell>
                     <GridCell colspan="2">
                        <View float="right">
                           <Checkbox
                              name="tmpMarketingSubscribe"
                              highlightColor={highlightColor}
                              label="Sign me up for the GNS3 newsletter "
                           />
                        </View>
                     </GridCell>
                  </Grid>
                  <View
                     width="100%"
                     margin="20px 0px 40px 0px"
                     height="75px"
                     display="flex"
                     flexFlow="column"
                     justifyContent="space-between"
                     alignItems="center">
                     <Button
                        color={highlightColor}
                        type="submit"
                        mode={isSubmitting ? 'loading' : 'normal'}
                        name="Create Account"
                        width="100%"
                        size="lg"
                     />
                     <View>
                        <Link to="/account/login">
                           <Text size="2" weight="medium" color="#888">Go to Login</Text>
                        </Link>
                     </View>
                  </View>
                  <View width="100%" textAlign="center">
                     <Text
                        size="1"
                        color="#999"
                        className={css`
                           a {
                              color: #666;
                              text-decoration: underline;
                           }
                        `}>
                        By creating an account, you agree to the&nbsp;
                        <a
                           href="https://www.solarwinds.com/legal/terms"
                           target="_blank"
                           rel="noopener noreferrer">
                           GNS3 Terms and Conditions
                        </a>
                        &nbsp;and&nbsp;
                        <a
                           href="https://www.solarwinds.com/legal/privacy"
                           target="_blank"
                           rel="noopener noreferrer">
                           Privacy Policy
                        </a>
                     </Text>
                  </View>
               </Form>
            )}
         />
      </AccountContainer>
   )
}

export default Register
