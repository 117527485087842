import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'

// =======================================================
// Container
// =======================================================

const SidebarLayoutContainer = ({ children }) => {
   return (
      <View
         display="flex"
         width="100%"
         height="100%"
         style={{ overflow: 'hidden' }}>
         {children}
      </View>
   )
}

SidebarLayoutContainer.propTypes = {
   children: PropTypes.node.isRequired
}

// =======================================================
// Nav
// =======================================================

const SidebarLayoutNav = ({ children, mode }) => {
   return (
      <nav
         style={{
            position: 'relative',
            flex: '0 0 auto',
            width: mode === 'root' ? '200px' : '240px',
            height: '100%',
            backgroundColor: 'var(--rootMenuBackgroundColor)',
            borderRight: '1px solid var(--borderColumnColor)'
         }}>
         {children}
      </nav>
   )
}

SidebarLayoutNav.propTypes = {
   children: PropTypes.node.isRequired,
   mode: PropTypes.string
}

SidebarLayoutNav.defaultProps = {
   mode: 'normal'
}

// =======================================================
// Main
// =======================================================

const SidebarLayoutMain = ({ children, mode }) => {
   return (
      <main
         className={`ui-navigation-view ${mode === 'root' ? 'root' : 'leaf'}`}
         style={{
            position: 'relative',
            height: '100%',
            backgroundColor: 'var(--rootMenuBackgroundColor)',
            boxShadow: mode === 'root' && '-10px 0px 50px rgba(0,0,0,0.03)',
            width: mode === 'root'
               ? 'calc(100% - 200px)'
               : 'calc(100% - 240px)',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px'
         }}>
         <View
            style={{
               width: '100%',
               height: '100%',
               overflow: 'hidden',
               borderTopLeftRadius: '10px',
               borderBottomLeftRadius: '10px'
            }}>
            {children}
         </View>
      </main>
   )
}

SidebarLayoutMain.propTypes = {
   children: PropTypes.node.isRequired,
   mode: PropTypes.string
}

SidebarLayoutMain.defaultProps = {
   mode: 'normal'
}

// =======================================================
// Export
// =======================================================

export default {
   Container: SidebarLayoutContainer,
   Main: SidebarLayoutMain,
   Nav: SidebarLayoutNav
}
