import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link, useRouteMatch } from 'react-router-dom'
import { AddIcon } from 'assets/icons'
import { IconButton } from 'src/core/kits/UI'

const MediumCreateControl = ({ iconSize }) => {
   const match = useRouteMatch()
   const isIndex = match.url === '/'

   return (
      <Link to={`${!isIndex ? match.url : ''}/-/create`}>
         <IconButton>
            <View display="flex" alignItems="center" opacity="0.5">
               <AddIcon
                  width={iconSize}
                  height={iconSize}
                  color="var(--textColor)"
                  strokeWidth="2px"
               />
            </View>
         </IconButton>
      </Link>
   )
}

MediumCreateControl.propTypes = {
   iconSize: PropTypes.string
}

MediumCreateControl.defaultProps = {
   iconSize: '20px'
}

export default MediumCreateControl
