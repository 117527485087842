import { useQuery } from '@apollo/client'
import { getNote as getNoteGql }
   from 'src/core/graphql/queries/getNote.gql'

const useNote = (variables, options = {}) => {
   const { data, ...otherQueryResults } = useQuery(getNoteGql, {
      variables,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: false,
      ...options
   })

   return {
      initiative: data?.initiative,
      ...otherQueryResults
   }
}

export default useNote
