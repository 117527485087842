// =========================================================================================@@
// Last Updated Date: Feb 3, 2022
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import React from 'react'
import ReactDOM from 'react-dom'
import { NotificationManagerProvider, OIOProvider } from 'oio-react'
import { BrowserRouter, Link } from 'react-router-dom'
import { init as initSentry } from '@sentry/browser'
import { sentryDsn } from 'config/constants'
import Global from 'src/core/kits/Global'
import { SocketProvider } from 'src/core/kits/Utils/SocketProvider'
import OrganizationProvider from 'src/sites/kits/Utils/OrganizationProvider'
import ApolloProvider from 'src/sites/kits/Utils/ApolloProvider'
import ErrorBoundary from 'src/sites/kits/Utils/ErrorBoundary'
import './style/index.less'
import 'react-dates/initialize'

initSentry({
   dsn: sentryDsn,
   environment: process.env.NODE_ENV,
   release: process.env.CV5_RELEASE_ID,
   sampleRate: process.env.NODE_ENV === 'production' ? 1 : 0
})

const oioButtonLinkAdapter = {
   render: ({ linkTo, linkReplace, linkExact, ...props }) => (
      <Link to={linkTo} replace={linkReplace} {...props} />
   )
}

ReactDOM.render((
   <OIOProvider
      buttonLinkAdapter={oioButtonLinkAdapter}
      fontFamily="switzer, -apple-system, neue-haas-unica, Helvetica Neue, Helvetica, sans-serif">
      <ErrorBoundary>
         <ApolloProvider>
            <BrowserRouter>
               <NotificationManagerProvider>
                  <OrganizationProvider>
                     <SocketProvider>
                        <Global />
                     </SocketProvider>
                  </OrganizationProvider>
               </NotificationManagerProvider>
            </BrowserRouter>
         </ApolloProvider>
      </ErrorBoundary>
   </OIOProvider>
), document.getElementById('container'))

if (module.hot) {
   module.hot.accept()
}
