import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, Modal, NotificationManagerContext, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { useCreateInitiative, useOrganization } from 'src/core/graphql/hooks'

const MediumCreatePrompt = ({
   buttonColor,
   buttonTextColor,
   modalOnCloseComplete,
   modalOnCloseTrigger,
   modalOpen,
   modalWidth,
   nameInputMaxLength,
   onCreate,
   parentInitiativeId,
   privacy
}) => {
   const [currentInputCharLength, setCurrentInputCharLength] = useState(0)
   const { showNotification } = useContext(NotificationManagerContext)
   const { organization } = useOrganization()
   const { createInitiative } = useCreateInitiative()
   const mediumType = organization.initiativeTypes.find(t => t.class === 'medium')

   const handleCreate = async (values, actions) => {
      try {
         const result = await createInitiative({
            ...values,
            parentInitiativeId,
            privacy: 'inherit',
            typeId: mediumType.id
         })

         if (onCreate) {
            onCreate(result.data.createInitiative)
         }
      } catch (err) {
         showNotification({
            message: err?.graphQLErrors?.map(e => e.message).join(', ') || err.message,
            title: 'Error',
            type: 'error'
         })
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <Modal
         borderRadius="6px"
         width={modalWidth}
         onCloseComplete={modalOnCloseComplete}
         onCloseTrigger={modalOnCloseTrigger}
         open={modalOpen}>
         <Formik
            initialValues={{ name: '' }}
            onSubmit={handleCreate}
            render={({ handleSubmit, handleChange, isSubmitting }) => (
               <Form
                  elementAppearance="plain"
                  elementBackgroundColor="#eee"
                  elementFocusBackgroundColor="#f5f5f5"
                  onSubmit={handleSubmit}>
                  <View padding="15px">
                     <View>
                        <Text size="2" color="#444" weight="medium">
                           Please enter a Name...
                        </Text>
                        <Text size="2" color="#888">
                           {`(${currentInputCharLength} of ${nameInputMaxLength} max characters)`}
                        </Text>
                        <Spacer size="2" />
                        <Input
                           maxLength={nameInputMaxLength}
                           name="name"
                           size="lg"
                           onChange={(e) => {
                              handleChange(e)
                              setCurrentInputCharLength(e.target.value.length)
                           }}
                           placeholder="Name"
                           required
                        />
                     </View>
                     <Spacer size="3" />
                     <View display="flex" width="100%" justifyContent="flex-end">
                        <View
                           width="100%"
                           maxWidth="270px"
                           display="flex"
                           justifyContent="space-between">
                           <Button
                              onClick={modalOnCloseTrigger}
                              width="49%"
                              name="Cancel"
                              color="#fff"
                              size="md"
                              textColor="#666"
                           />
                           <Button
                              width="49%"
                              type="submit"
                              name="Save"
                              color="var(--formFieldBackgroundColor)"
                              size="md"
                              textColor="var(--textColor)"
                              mode={isSubmitting ? 'loading' : 'normal'}
                           />
                        </View>
                     </View>
                  </View>
               </Form>
            )}
         />
      </Modal>
   )
}

MediumCreatePrompt.propTypes = {
   buttonColor: PropTypes.string,
   buttonTextColor: PropTypes.string,
   modalOnCloseComplete: PropTypes.func.isRequired,
   modalOnCloseTrigger: PropTypes.func.isRequired,
   modalOpen: PropTypes.bool.isRequired,
   modalWidth: PropTypes.string,
   nameInputMaxLength: PropTypes.string,
   onCreate: PropTypes.func,
   parentInitiativeId: PropTypes.string,
   privacy: PropTypes.string
}

MediumCreatePrompt.defaultProps = {
   buttonColor: undefined,
   buttonTextColor: undefined,
   modalWidth: '90%[a-b] 300px[c-f]',
   nameInputMaxLength: '120',
   onCreate: undefined,
   parentInitiativeId: undefined,
   privacy: undefined
}

export default MediumCreatePrompt
