import React from 'react'
import PropTypes from 'prop-types'

const Button = ({ children, onClick }) => (
   <div
      className="ui-button"
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex="0">
      {children}
   </div>
)

Button.propTypes = {
   children: PropTypes.node.isRequired,
   onClick: PropTypes.func
}

Button.defaultProps = {
   onClick: undefined
}

export default Button
