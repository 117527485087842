import React, { useContext } from 'react'
import { Spinner, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useMe, useOrganization } from 'src/core/graphql/hooks'
import CoverPage from 'src/core/kits/Medium/apps/CoverPage'
import Collection from 'src/core/kits/Medium/apps/Collection'
import Note from 'src/core/kits/Medium/apps/Note'
import Notebook from 'src/core/kits/Medium/apps/Notebook'
import Table from 'src/core/kits/Medium/apps/Table'
import MediumNav from 'src/core/kits/Medium/components/Nav'
import { SidebarLayout, TabbarLayout } from 'src/core/kits/UI'
import { InitiativeHierarchyContext, withInitiativeHierarchyProvider }
   from 'src/sites/kits/Utils/InitiativeHierarchy'
import Routes from '../routes' // eslint-disable-line import/no-cycle

const MediumView = ({ onDelete }) => {
   const { initiative: medium, initiativeLoading } = useContext(InitiativeHierarchyContext)
   const { organization } = useOrganization()
   const { me } = useMe()
   const isPersonalMedium = organization.id === me?.personalSpaceId

   if (initiativeLoading) {
      return (
         <View
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            textAlign="center"
            padding="90px"
            borderRadius="6px">
            <Spinner color="var(--borderColor)" width="24px" height="24px" />
         </View>
      )
   }

   const isGrid = medium.protoFormat === 'grid'
   const isTable = medium.protoFormat === 'table'
   const isTabs = medium.protoFormat === 'tabs'
   const isSidebar = medium.protoFormat === 'sidebar'
   const isNotebook = medium.protoFormat === 'notebook'
   const isCoverPage = medium.protoFormat === 'cover'
   const isNote = medium.protoFormat === 'page'

   return (
      <>
         {isTabs && (
            <TabbarLayout.Container>
               <TabbarLayout.Nav>
                  <MediumNav orientation="horizontal" />
               </TabbarLayout.Nav>
               <TabbarLayout.Main>
                  <Routes />
               </TabbarLayout.Main>
            </TabbarLayout.Container>
         )}
         {isSidebar && (
            <SidebarLayout.Container>
               <SidebarLayout.Nav
                  mode={isPersonalMedium && medium.ancestors.length === 0 ? 'root' : 'normal'}>
                  <MediumNav orientation="vertical" />
               </SidebarLayout.Nav>
               <SidebarLayout.Main
                  mode={isPersonalMedium && medium.ancestors.length === 0 ? 'root' : 'normal'}>
                  <Routes />
               </SidebarLayout.Main>
            </SidebarLayout.Container>
         )}
         {isNote && (
            <Note
               body={medium.body}
               bodyVersion={medium.body?.v}
               dateLastUpdated={medium.dateLastUpdated}
               id={medium.id}
               name={medium.name}
               onDelete={onDelete}
            />
         )}
         {isCoverPage && (
            <CoverPage
               name={medium.name}
               onDelete={onDelete}
            />
         )}
         {isTable && <Table onDelete={onDelete} />}
         {isGrid && <Collection onDelete={onDelete} />}
         {isNotebook && <Notebook onDelete={onDelete} />}
         {(isCoverPage || isGrid || isNote || isNotebook || isTable) && <Routes />}
      </>
   )
}

MediumView.propTypes = {
   onDelete: PropTypes.func.isRequired
}

export default withInitiativeHierarchyProvider({ slugParam: 'mediumSlug' })(MediumView)
