import React from 'react'
import { css } from 'emotion'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const AppIconListItem = ({ linkExact, linkTo, name, onClick, shared }) => {
   const activeButtonStyle = css`
      color: var(--buttonActiveTextColor) !important;
      background-color: var(--buttonActiveBackgroundColor);
      opacity: 1;
   `

   const isLink = linkTo
   const Button = isLink ? NavLink : 'div'
   const buttonProps = isLink
      ? { to: linkTo, exact: linkExact, activeClassName: activeButtonStyle }
      : {}

   return (
      <Button
         {...buttonProps}
         onClick={onClick}
         className={css`
            display: block;
            cursor: pointer;
            user-select: none;
            user-drag: none;
            padding: 15px;
            margin: 15px;
            border-radius: 8px;
            color: var(--buttonTextColor);

            &:hover {
               background-color: var(--buttonActiveBackgroundColor);
               color: var(--buttonActiveTextColor) !important;
            }

            &:active {
               transform: translate3d(0, 0, 0);
            }
         `}>
         <View
            display="flex"
            flexFlow="column"
            alignItems="center"
            width="100px"
            textAlign="center"
            style={{ gap: '20px' }}>
            <View
               flex="0 0 auto"
               width="90px"
               height="90px"
               borderRadius="8px"
               opacity="0.9"
               style={{
                  border: '1px solid var(--buttonTextColor)'
               }}
            />
            {name}
            {shared && <div style={{ opacity: 0.5 }}>Shared</div>}
         </View>
      </Button>
   )
}

AppIconListItem.propTypes = {
   linkExact: PropTypes.bool,
   linkTo: PropTypes.string,
   name: PropTypes.string.isRequired,
   onClick: PropTypes.func,
   shared: PropTypes.bool
}

AppIconListItem.defaultProps = {
   linkExact: false,
   linkTo: undefined,
   onClick: undefined,
   shared: false
}

export default AppIconListItem
