import React from 'react'
import PropTypes from 'prop-types'

// =======================================================
// Container
// =======================================================

const TabbarContainer = ({ children }) => {
   const userAgent = window.navigator.userAgent.toLowerCase()
   const isElectron = userAgent.includes(' electron/')

   return (
      <div
         style={{
            width: '100%',
            height: isElectron ? 'calc(100% - var(--viewportDesktopTitlebarHeight))' : '100%',
            overflow: 'hidden'
         }}>
         {children}
      </div>
   )
}

TabbarContainer.propTypes = {
   children: PropTypes.node.isRequired
}

// =======================================================
// Nav
// =======================================================

const TabbarNav = ({ children }) => {
   return (
      <nav
         style={{
            width: '100%',
            height: '70px',
            padding: '0px 15px 0px 25px',
            borderBottom: '1px solid var(--borderColumnColor)'
         }}>
         {children}
      </nav>
   )
}

TabbarNav.propTypes = {
   children: PropTypes.node.isRequired
}

// =======================================================
// Main
// =======================================================

const TabbarMain = ({ children }) => {
   return (
      <main
         style={{
            position: 'relative',
            height: 'calc(100% - 70px)',
            width: '100%',
            overflow: 'auto'
         }}>
         {children}
      </main>
   )
}

TabbarMain.propTypes = {
   children: PropTypes.node.isRequired
}

// =======================================================
// Export
// =======================================================

export default {
   Container: TabbarContainer,
   Main: TabbarMain,
   Nav: TabbarNav
}
