import { useQuery } from '@apollo/client'
import { getMediumList as getMediumListGql }
   from 'src/core/graphql/queries/getMediumList.gql'

const useMediumList = (variables, options) => {
   const queryOptions = {
      variables,
      notifyOnNetworkStatusChange: false,
      errorPolicy: 'all',
      ...options
   }

   const { data, error, ...otherQueryReturnValues } = useQuery(getMediumListGql, queryOptions)
   const hasFatalErrors = queryOptions.errorPolicy !== 'ignore' && (
      error?.networkError ||
      error?.graphQLErrors?.some(err => err?.extensions?.exception?.name !== 'NonFatal')
   )

   if (hasFatalErrors) {
      throw error
   }

   return {
      ...otherQueryReturnValues,
      error,
      mediumList: data
         ? data.mediumList
         : { items: [], listInfo: {} }
   }
}

export default useMediumList
