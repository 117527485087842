// =========================================================================================@@
// Last Updated Date: Apr 4, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import React, { createRef, useContext, useState, useCallback, useEffect, useRef } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link, useRouteMatch } from 'react-router-dom'
import { useRemoveInitiative, useUpdateInitiative } from 'src/core/graphql/hooks'
import MediumCreateInput from 'src/core/kits/Medium/components/CreateInput'
import EditInPlaceInput from 'src/core/kits/Medium/components/MetadataBlock/EditInPlaceInput'
import LiveTokenInput from 'src/core/kits/Medium/components/MetadataBlock/LiveTokenInput'
import { ListMenuButton } from 'src/core/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import Popover from 'src/sites/kits/Utils/Popover'

// TODO: Does not account for changes to metadataFields, since refs need to be updated
// accordingly, which does not trigger changes. We might want to use stateful refs here.
// See: #1143
const TableSpreadsheet = ({
   mediumCreationProps,
   mediumListItems,
   metadataFields,
   minCellWidth,
   onRowUpdate
}) => {
   const match = useRouteMatch()
   const spreadsheetContainer = useRef()
   const tableElement = useRef(null)
   const [tableHeight, setTableHeight] = useState('auto')
   const [activeColumnIndex, setActiveColumnIndex] = useState(null)

   // We force a table header cell at the beginning called name, and one at the end that is empty
   const tableHeaders = [{ key: 'Name' }].concat(metadataFields).concat([{ key: '' }])
   const numTableColumns = tableHeaders.length
   const tableColumnHeaderRefs = useRef(tableHeaders.map(createRef))

   const { initiative: medium } = useContext(InitiativeHierarchyContext)
   const { removeInitiative } = useRemoveInitiative()
   const { updateInitiative } = useUpdateInitiative()

   // All this column resize logic is temporary and is subject to change PR #1128
   const handleColumnDrag = (index) => {
      setActiveColumnIndex(index)
   }

   const handleColumnResize = useCallback((e) => {
      const gridColumns = tableColumnHeaderRefs.current.map((colRef, i) => {
         if (i === activeColumnIndex) {
            const containerOffset = spreadsheetContainer.current.getBoundingClientRect().left +
               document.documentElement.scrollLeft

            const width = e.clientX - colRef.current.offsetLeft - containerOffset

            if (width >= minCellWidth) {
               return `${width}px`
            }
         }

         return `${colRef.current.offsetWidth}px`
      })

      tableElement.current.style.gridTemplateColumns = `${gridColumns.join(' ')}`
   }, [activeColumnIndex, minCellWidth])

   const removeColumnResizeListeners = useCallback(() => {
      window.removeEventListener('mousemove', handleColumnResize)
      window.removeEventListener('mouseup', removeColumnResizeListeners)
   }, [handleColumnResize])

   const handleMouseUp = useCallback(() => {
      setActiveColumnIndex(null)
      removeColumnResizeListeners()
   }, [setActiveColumnIndex, removeColumnResizeListeners])

   const handleResetTableCellSizes = () => {
      tableElement.current.style.gridTemplateColumns =
         `minmax(150px, 3fr) ${'minmax(150px, 1fr)'.repeat(numTableColumns - 1)}`
   }

   const handleDelete = async (mediumId) => {
      try {
         await removeInitiative({ id: mediumId })
      } catch (err) {
         window.alert(err.message)
      }
   }

   const handleUpdateMetadataValue = async (mediumId, metadataFieldId, newMetadataValue) => {
      await updateInitiative({ id: mediumId }, {
         metadataValues: [{ fieldId: metadataFieldId, value: newMetadataValue }]
      }, {
         fetchPolicy: 'no-cache'
      })

      onRowUpdate()
   }

   useEffect(() => {
      // Allow the table to render before setting the height
      setTimeout(() => {
         setTableHeight(tableElement.current.offsetHeight)
      }, 100)
   }, [])

   useEffect(() => {
      if (activeColumnIndex !== null) {
         window.addEventListener('mousemove', handleColumnResize)
         window.addEventListener('mouseup', handleMouseUp)
      }

      return () => {
         removeColumnResizeListeners()
      }
   }, [activeColumnIndex, handleColumnResize, handleMouseUp, removeColumnResizeListeners])

   return (
      <>
         <View width="100%">
            <div ref={spreadsheetContainer} className="gather-spreadsheet-container">
               <Popover.Provider>
                  <div className="table-wrapper">
                     <table
                        ref={tableElement}
                        style={{
                           gridTemplateColumns: `minmax(150px, 2fr) ${'minmax(150px, 1fr)'.repeat(numTableColumns - 1)}`
                        }}>
                        <thead>
                           <tr>
                              {tableHeaders.map(({ key }, i) => (
                                 <th ref={tableColumnHeaderRefs.current[i]} key={key}>
                                    <span>{key}</span>
                                    <div
                                       role="presentation"
                                       style={{ height: tableHeight }}
                                       onMouseDown={() => handleColumnDrag(i)}
                                       className={`resize-handle ${
                                          activeColumnIndex === i ? 'active' : 'idle'
                                       }`}
                                    />
                                 </th>
                              ))}
                           </tr>
                        </thead>
                        <tbody>
                           {mediumListItems.map((nestedMedium, index) => (
                              <tr key={nestedMedium.id} className="data-row">
                                 <td>
                                    <Link to={nestedMedium.gatherUrl}>
                                       {nestedMedium.name}
                                    </Link>
                                 </td>
                                 {metadataFields.map((field) => {
                                    // eslint-disable-next-line max-len
                                    const metadataFieldValue = nestedMedium.metadataValuesObj[field.id]

                                    return (
                                       <td key={field.id}>
                                          {field.type === 'token' && (
                                             <LiveTokenInput
                                                defaultValue={metadataFieldValue}
                                                onChange={(newValue) => {
                                                   handleUpdateMetadataValue(
                                                      nestedMedium.id,
                                                      field.id,
                                                      newValue
                                                   )
                                                }}
                                             />
                                          )}
                                          {field.type !== 'token' && (
                                             <EditInPlaceInput
                                                defaultValue={metadataFieldValue}
                                                onDebouncedChange={(newValue) => {
                                                   handleUpdateMetadataValue(
                                                      nestedMedium.id,
                                                      field.id,
                                                      newValue
                                                   )
                                                }}
                                             />
                                          )}
                                       </td>
                                    )
                                 })}
                                 <td>
                                    <View display="flex" justifyContent="flex-end">
                                       <Popover.Anchor meta={nestedMedium} tabIndex={index}>
                                          <View>
                                             ...
                                          </View>
                                       </Popover.Anchor>
                                    </View>
                                 </td>
                              </tr>
                           ))}
                           <tr>
                              <td className="table-footer-cell">
                                 <MediumCreateInput
                                    metadataValues={mediumCreationProps?.metadataValues}
                                    parentInitiativeId={medium.id}
                                 />
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <Popover.View
                     anchorOriginHorizontal="right"
                     borderRadius="4px"
                     width="120px">
                     {anchorMedium => (
                        <div className="ui-popover">
                           <ListMenuButton
                              linkTo={`${match.url}/-/move/${anchorMedium.id}/${anchorMedium.name}`}
                              name="Move"
                           />
                           <ListMenuButton
                              name="Delete"
                              onClick={() => handleDelete(anchorMedium.id)}
                           />
                        </div>
                     )}
                  </Popover.View>
               </Popover.Provider>
            </div>
         </View>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="var(--toolbarHeight)"
            padding="0px 20px">
            <View onClick={handleResetTableCellSizes}>Reset</View>
         </View>
      </>
   )
}

TableSpreadsheet.propTypes = {
   mediumCreationProps: PropTypes.object,
   metadataFields: PropTypes.array,
   minCellWidth: PropTypes.number,
   onRowUpdate: PropTypes.func.isRequired
}

TableSpreadsheet.defaultProps = {
   mediumCreationProps: {},
   metadataFields: [],
   minCellWidth: 120
}

export default TableSpreadsheet
