// =========================================================================================@@
// Last Updated Date: March 22, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { useMediumList, useMetadataFieldList } from 'src/core/graphql/hooks'
import MediumMoreControl from 'src/core/kits/Medium/components/MoreControl'
import MediumTitleEditable from 'src/core/kits/Medium/components/TitleEditable'
import { Title } from 'src/core/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import Spreadsheet from './Spreadsheet'

const TableMedium = ({ onDelete }) => {
   const { initiative: medium } = useContext(InitiativeHierarchyContext)
   const sortItemsBy = medium.metadataValues.find(f => f.key === 'sortItemsBy')?.value || 'recent'
   const groupItemsByField = medium.metadataValues.find(f => f.key === 'groupItemsBy')?.value
   const itemsAreGrouped = groupItemsByField && Object.keys(groupItemsByField).length > 0

   const { mediumList, refetch } = useMediumList({
      groupBy: groupItemsByField,
      parentInitiativeId: medium?.id,
      sortBy: sortItemsBy
   }, { skip: !medium?.id })

   const { metadataFieldList: rulesMetadataFieldList, loading } = useMetadataFieldList({
      sources: [{ id: medium.id, type: 'parent' }]
   }, { skip: !medium?.id })

   if (loading) {
      return null
   }

   return (
      <>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="90px"
            padding="0px 20px">
            <MediumTitleEditable
               id={medium.id}
               name={medium.name}
               size="md"
            />
            <MediumMoreControl
               onDelete={onDelete}
               popoverAnchorOriginHorizontal="right"
               popoverAnchorOriginVertical="top"
            />
         </View>
         <View width="100%" height="calc(100% - 90px)" scroll="on">
            {itemsAreGrouped && mediumList.items.map(itemGrouping => (
               <View key={itemGrouping.name}>
                  <View padding="5px 20px 20px 20px">
                     <Title size="sm">
                        {itemGrouping.name}
                     </Title>
                  </View>
                  <Spreadsheet
                     mediumCreationProps={{
                        metadataValues: [{
                           fieldId: groupItemsByField.metadataFieldId,
                           // eslint-disable-next-line max-len
                           value: itemGrouping.items?.[0]?.metadataValuesObj?.[groupItemsByField.metadataFieldId]
                        }]
                     }}
                     mediumListItems={itemGrouping.items}
                     metadataFields={rulesMetadataFieldList}
                     onRowUpdate={refetch}
                  />
               </View>
            ))}
            {!itemsAreGrouped && (
               <Spreadsheet
                  mediumListItems={mediumList.items}
                  metadataFields={rulesMetadataFieldList}
                  onRowUpdate={refetch}
               />
            )}
         </View>
      </>
   )
}

TableMedium.propTypes = {
   onDelete: PropTypes.func.isRequired
}

export default TableMedium
