import React from 'react'
import { css } from 'emotion'
import { View } from 'oio-react'
import { useMe } from 'src/core/graphql/hooks'
import { ArrowDownIcon, IconButton, ListMenuButton } from 'src/core/kits/UI'
import Popover from 'src/sites/kits/Utils/Popover'

const appMenuButtonProps = { height: '30px' }

const GlobalAccountControl = () => {
   const { me, isLoggedIn, loading: userIsLoading } = useMe()

   if (!isLoggedIn || userIsLoading) {
      return null
   }

   return (
      <View
         display="flex"
         alignItems="center"
         height="36px">
         <View display="flex" alignItems="center" marginRight="5px">
            <View className="ui-paragraph-text">
               {me.fullName}
            </View>
         </View>
         <Popover.Provider>
            <Popover.Anchor>
               <IconButton>
                  <ArrowDownIcon
                     className={css`opacity:0.8; margin-bottom: 20px;`}
                     fill="var(--buttonTextColor)"
                  />
               </IconButton>
            </Popover.Anchor>
            <Popover.View
               anchorOriginHorizontal="right"
               anchorOriginVertical="bottom"
               backgroundColor="transparent"
               borderRadius="4px"
               width="200px"
               margin="0px">
               <div className="ui-popover">
                  <ListMenuButton
                     {...appMenuButtonProps}
                     linkTo="/account/logout"
                     name="Logout"
                  />
               </div>
            </Popover.View>
         </Popover.Provider>
      </View>
   )
}

export default GlobalAccountControl
