// =========================================================================================@@
// Last Updated Date: Feb 23, 2022
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { useMe, useOrganization } from 'src/core/graphql/hooks'
import AccountInvitation from 'src/sites/kits/Account/apps/Invitation'
import AppView from 'src/core/kits/App/apps/View'
import GlobalViewport from 'src/core/kits/Global/components/Viewport'
import MediumView from 'src/core/kits/Medium/apps/View'
import Account from 'src/sites/kits/Account'
import ErrorBoundary from 'src/sites/kits/Utils/ErrorBoundary'

const OpsCentre = React.lazy(() => import('src/sites/kits/Ops'))

const Global = () => {
   const history = useHistory()
   const { me, isLoggedIn, loading: userIsLoading } = useMe()
   const { organization, loading: organizationIsLoading } = useOrganization()

   if (userIsLoading || organizationIsLoading) {
      return null
   }

   if (!isLoggedIn) {
      return (
         <GlobalViewport>
            <Switch>
               <Route path="/account" component={Account} />
               <Redirect from="/invitation" to="/-/invitation" />
               <Route path="/-/invitation" component={AccountInvitation} />
               <Redirect to="/account/login" />
            </Switch>
         </GlobalViewport>
      )
   }

   // TODO: This should not even exist
   // See: #1106
   if (!organization) {
      return (
         <GlobalViewport>
            No Organization Context Set
         </GlobalViewport>
      )
   }

   const currentSessionIsPersonal = organization.id === me.personalSpaceId

   return (
      <GlobalViewport>
         <Switch>
            {/* Operational Tools Limited to Mother Users */}
            <Route
               path="/-/ops"
               render={() => (
                  <React.Suspense fallback={null}>
                     <OpsCentre />
                  </React.Suspense>
               )}
            />

            <Route path="/account" component={Account} />
            <Redirect from="/invitation" to="/-/invitation" />
            <Route path="/-/invitation" component={AccountInvitation} />

            {currentSessionIsPersonal && (
               <Route
                  path="/:mediumSlug"
                  render={routeProps => (
                     <ErrorBoundary key={routeProps.match.params.mediumSlug} height="100vh">
                        <MediumView onDelete={() => history.push('/')} />
                     </ErrorBoundary>
                  )}
               />
            )}
            {!currentSessionIsPersonal && (
               <Route path="/" component={AppView} />
            )}
         </Switch>
      </GlobalViewport>
   )
}

export default Global
