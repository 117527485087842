// =========================================================================================@@
// Last Updated Date: Jun 28, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import GNS3Register from 'src/sites/custom/gns3/Register' // Custom Registration Page
import { useOrganization } from 'src/core/graphql/hooks'
import ForgotPassword from './apps/ForgotPassword'
import Login from './apps/Login'
import Logout from './apps/Logout'
import Register from './apps/Register'
import ResetPassword from './apps/ResetPassword'
import Unsubscribe from './apps/Unsubscribe'

const Account = () => {
   const match = useRouteMatch()
   const { organization } = useOrganization()

   return (
      <Switch>
         <Route path={`${match.url}/login`} component={Login} />
         <Route path={`${match.url}/logout`} component={Logout} />
         <Route path={`${match.url}/forgot-password`} component={ForgotPassword} />
         <Route
            path={`${match.url}/register`}
            component={organization?.slug === 'gns3' ? GNS3Register : Register}
         />
         <Route path={`${match.url}/reset-password`} component={ResetPassword} />
         <Route path={`${match.url}/unsubscribe/:status`} component={Unsubscribe} />
      </Switch>
   )
}

export default Account
