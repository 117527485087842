export default {
   // Global
   tmpGlobalLayout: 'sidebar',
   layoutContentFillMode: 'fluid',
   tmpAppInnerPaddingHorizontal: '20px[a-b] 30px[c] 30px[d] 30px[e-f]',
   tmpAppOuterPaddingHorizontal: '20px[a-b] 40px[c] 40px[d] 50px[e-f]',
   tmpHeaderHeight: '72px',
   // Spacing
   tmpSpacing: '25px[a-d] 30px[e] 40px[f]',
   tmpSpacingTop: '25px[a-b] 30px[c-d] 40px[e-f]',
   // Viewport Constants
   tmpViewportHeight: `
      calc(100vh - 72px)[a-c]
      calc(100vh - 0px)[d-f]
   `,
   tmpContentFixedAreaHeight: `
      calc(100vh - 72px)[a-c]
      100vh[d-f]
   `,
   viewportMarginTop: '0px',
   viewportContentMaxWidth: 'auto',
   tmpViewportPadding: '16px[a] 20px[b] 20px[c] 20px[d-e] calc(50vw - 900px + 150px)[f]',
   tmpViewportPaddingA: '16px',
   tmpViewportPaddingB: '20px',
   tmpViewportPaddingC: '20px',
   tmpViewportPaddingD: '20px',
   tmpViewportPaddingE: '20px',
   tmpViewportPaddingF: 'calc(50vw - 900px + 150px)'
}
