import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import MediumMoreControl from 'src/core/kits/Medium/components/MoreControl'

const CoverPage = ({ name, onDelete }) => (
   <div className="gather-cover-page">
      <div className="tmp-title">
         {name}
      </div>
      <View
         display="flex"
         alignItems="center"
         height="var(--toolbarHeight)"
         position="fixed"
         bottom="0px"
         right="0px"
         padding="0px 20px"
         zIndex="2">
         <MediumMoreControl
            onDelete={onDelete}
            popoverAnchorOriginHorizontal="right"
         />
      </View>
   </div>
)

CoverPage.propTypes = {
   name: PropTypes.string.isRequired,
   onDelete: PropTypes.func.isRequired
}

export default CoverPage
