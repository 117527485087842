import React, { useCallback, useEffect } from 'react'
import { View } from 'oio-react'
import { Link, useHistory } from 'react-router-dom'
import { useInitiativeList, useMe, useOrganization, useSpaceList } from 'src/core/graphql/hooks'
import AccountControl from 'src/core/kits/Global/components/AccountControl'
import { useGlobalState } from 'src/core/state'
import { ORGANIZATION_HEADER_KEY } from 'src/sites/kits/Utils/apiFetch'
import { wsLink } from 'src/sites/kits/Utils/apolloClient'
import AppIconListItem from '../AppIconListItem'
import TOCToggleControl from '../ToggleControl'

// Shared Apps (organizations) that will show up in the TOC
const sharedAppSlugs = ['mother-co', 'kaisa-steven']

const TOCMenu = () => {
   const history = useHistory()
   const { setTocDisplayMode, tocDisplayMode, setHeadingStyle, setTheme } = useGlobalState()
   const isTOCOverlay = tocDisplayMode === 'overlay'

   const currentSessionOrgId = window.sessionStorage.getItem('ORGANIZATION_ID')
   const { refetch: refetchOrganization } = useOrganization()
   const { spaceList } = useSpaceList()
   const { me, isLoggedIn } = useMe()
   const isGatherDeveloper = me?.email.endsWith('@mother.co')

   const { initiativeList: personalList } = useInitiativeList({
      sortBy: 'name'
   }, {
      skip: !isLoggedIn || !me?.personalSpaceId,
      context: {
         headers: {
            [ORGANIZATION_HEADER_KEY]: me?.personalSpaceId
         }
      }
   })

   const toggleToc = useCallback((event) => {
      const tagName = event.target.tagName.toLowerCase()
      const isContentEditable = event.target.contentEditable === 'true'

      if (tagName === 'input' || tagName === 'textarea' || isContentEditable) {
         return
      }

      if (event.keyCode === 32) {
         event.preventDefault()

         setTocDisplayMode((prevDisplayMode) => {
            let newDisplayMode = 'hidden'

            if (prevDisplayMode === 'hidden') {
               newDisplayMode = 'overlay'
            }

            return newDisplayMode
         })
      }
   }, [])

   const handleSwitchSpace = (newSpaceId, redirectUrl) => {
      window.sessionStorage.setItem('ORGANIZATION_ID', newSpaceId)
      wsLink.subscriptionClient.close(false)
      wsLink.subscriptionClient.connect()
      history.push(redirectUrl)
      refetchOrganization()
   }

   useEffect(() => {
      document.addEventListener('keydown', toggleToc, false)

      return () => {
         document.removeEventListener('keydown', toggleToc, false)
      }
   }, [])

   return (
      <>
         <View
            onClick={() => setTocDisplayMode('hidden')}
            onKeyPress={() => setTocDisplayMode('hidden')}
            role="button"
            tabIndex={0}
            position="fixed"
            top="0px"
            left="0px"
            right="0px"
            bottom="0px"
            transition="opacity 200ms"
            transform={isTOCOverlay ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)'}
            opacity={isTOCOverlay ? '1' : '0'}
            zIndex="9998"
         />
         <div className={`gather-toc ${tocDisplayMode}`}>
            <View className="gather-toc-nav">
               <View onClick={() => isTOCOverlay && setTocDisplayMode('hidden')}>
                  <View className="gather-toc-app-container">
                     {personalList.items.map((app) => {
                        const requiresSpaceSwitch = app.id !== currentSessionOrgId

                        if (requiresSpaceSwitch) {
                           return (
                              <AppIconListItem
                                 key={app.id}
                                 onClick={() => {
                                    handleSwitchSpace(me?.personalSpaceId, app.gatherUrl)
                                 }}
                                 name={app.name}
                              />
                           )
                        }

                        return (
                           <AppIconListItem
                              key={app.id}
                              linkTo={app.gatherUrl}
                              name={app.name}
                           />
                        )
                     })}
                     {spaceList.items
                        .filter(s => sharedAppSlugs.includes(s.slug))
                        .map((space) => {
                           const requiresSpaceSwitch = space.id !== currentSessionOrgId

                           if (requiresSpaceSwitch) {
                              return (
                                 <AppIconListItem
                                    key={space.id}
                                    onClick={() => handleSwitchSpace(space.id, '/')}
                                    name={space.name}
                                    shared
                                 />
                              )
                           }

                           return (
                              <AppIconListItem
                                 key={space.id}
                                 linkTo="/"
                                 name={space.name}
                                 shared
                              />
                           )
                        })}
                  </View>
               </View>
            </View>
            <View
               display="flex"
               justifyContent="space-between"
               alignItems="center"
               position="absolute"
               left="0px"
               right="0px"
               bottom="0px"
               padding="30px 50px">
               <View display="flex" style={{ gap: '20px' }}>
                  <View onClick={() => setTheme('light')}>
                     Light Mode
                  </View>
                  <View onClick={() => setTheme('dark')}>
                     Dark Mode
                  </View>
                  <View onClick={() => setHeadingStyle('sans')}>
                     Sans
                  </View>
                  <View onClick={() => setHeadingStyle('sans-titlecase')}>
                     Sans Title
                  </View>
                  <View onClick={() => setHeadingStyle('serif')}>
                     Serif
                  </View>
                  <View onClick={() => setHeadingStyle('serif-titlecase')}>
                     Serif Title
                  </View>
                  {isGatherDeveloper && (
                     <Link
                        onClick={() => setTocDisplayMode('hidden')}
                        to="/-/app-create">
                        <div className="ui-paragraph-text">
                           New App
                        </div>
                     </Link>
                  )}
               </View>
               <AccountControl />
            </View>
         </div>
         <View
            onClick={() => setTocDisplayMode('overlay')}
            onKeyPress={() => setTocDisplayMode('hidden')}
            role="button"
            tabIndex={0}
            position="fixed"
            left="0px"
            bottom="0px"
            display="flex"
            alignItems="center"
            height="var(--toolbarHeight)"
            padding="0px 10px"
            zIndex="9997">
            <TOCToggleControl />
         </View>
      </>
   )
}

export default TOCMenu
