// =========================================================================================@@
// Last Updated Date: Apr 4, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const MediumListItemRow = ({
   coverUrl,
   dateLastUpdated,
   linkTo,
   metadataValues,
   name,
   tags
}) => {
   return (
      <Link to={linkTo}>
         <View
            display="flex"
            alignItems="center"
            width="100%"
            padding="10px 0px"
            borderBottom="1px solid var(--borderColor)"
            style={{ gap: '20px', color: 'var(--textColor)' }}>
            <View width="30px" flex="0 0 auto">
               <View
                  width="100%"
                  paddingTop="100%"
                  borderRadius="4px"
                  backgroundColor="var(--formFieldBackgroundColor)"
                  backgroundImage={coverUrl && `url(${coverUrl})`}
                  backgroundSize="cover"
                  backgroundPosition="center center"
                  backgroundRepeat="no-repeat"
               />
            </View>
            <View flex="1 1 auto">
               <span style={{ color: 'var(--titleColor)', fontWeight: '600' }}>{name}</span>
               <div>
                  {/* // TODO: This displays medium metadata seperated by a dash.
                  This has to be done completely differently - PR #1127 */}
                  {metadataValues.map((metadataValue) => {
                     const isString = typeof metadataValue.value === 'string' && metadataValue.value
                     const isArray = Array.isArray(metadataValue.value) &&
                        metadataValue.value.length > 0

                     if (!isString && !isArray) {
                        return null
                     }

                     return (
                        <span key={metadataValue.fieldId} style={{ marginRight: '15px' }}>
                           {isString && metadataValue.value}
                           {isArray && metadataValue.value.map(v => (
                              <span key={v.id}>{v.name}</span>
                           ))}
                        </span>
                     )
                  })}
               </div>
            </View>
            <View display="flex" style={{ gap: '10px' }}>
               {tags.map(tag => (
                  <div key={tag}>{tag}</div>
               ))}
            </View>
            <div style={{ color: 'var(--metaColor)' }}>{dateLastUpdated}</div>
         </View>
      </Link>
   )
}

MediumListItemRow.propTypes = {
   coverUrl: PropTypes.string,
   dateLastUpdated: PropTypes.string.isRequired,
   linkTo: PropTypes.string.isRequired,
   metadataValues: PropTypes.array,
   name: PropTypes.string.isRequired,
   tags: PropTypes.array
}

MediumListItemRow.defaultProps = {
   coverUrl: undefined,
   metadataValues: [],
   tags: []
}

export default MediumListItemRow
