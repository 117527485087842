import React, { useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, Modal, NotificationInline, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon } from 'assets/icons'
import { useNote } from 'src/core/graphql/hooks'
import { Textarea } from 'src/sites/kits/Utils/ConnectedForm'
import { gql, useMutation, useQuery } from '@apollo/client'

const getRawMediumBodyGql = gql`
   query getRawMediumBody($id: ID!) {
      rawMediumBody(id: $id)
   }
`

const forceResetMediumBodyGql = gql`
   mutation forceResetMediumBody($id: ID!, $body: JSON) {
      forceResetMediumBody(id: $id, body: $body) {
         id
      }
   }
`

const applyNextPendingMediumBodyChangeGql = gql`
   mutation applyNextPendingMediumBodyChange($id: ID!) {
      applyNextPendingMediumBodyChange(id: $id) {
         numPendingChanges
      }
   }
`

const NoteForceReset = ({
   modalOnCloseComplete,
   modalOnCloseTrigger,
   modalOpen,
   noteId,
   onCloseButtonClick
}) => {
   const [error, setError] = useState()
   const [applyNextPendingMediumBodyChange] = useMutation(applyNextPendingMediumBodyChangeGql)
   const [forceResetMediumBody] = useMutation(forceResetMediumBodyGql)
   const [isApplyingChangePatch, setApplyingChangePatch] = useState(false)

   const {
      initiative: note,
      loading: noteLoading,
      refetch: refetchNote
   } = useNote({ id: noteId })

   const {
      data,
      error: queryError,
      loading: rawBodyLoading,
      refetch: refetchRawBody
   } = useQuery(getRawMediumBodyGql, { variables: { id: noteId } })

   const handleForceReset = async (values, actions) => {
      try {
         const newBody = JSON.parse(values.rawMediumBody)
         await forceResetMediumBody({ variables: { id: noteId, body: newBody } })
         await refetchRawBody()
      } catch (err) {
         console.log(err) // eslint-disable-line no-console
         setError(err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   const handleApplyNextChangePatch = async (resetFormState) => {
      try {
         setApplyingChangePatch(true)
         await applyNextPendingMediumBodyChange({ variables: { id: noteId } })
         const [newRawMediumBodyData] = await Promise.all([
            refetchRawBody(),
            refetchNote()
         ])

         resetFormState(JSON.stringify(newRawMediumBodyData.rawMediumBody, null, 3))
      } catch (err) {
         console.log(err) // eslint-disable-line no-console
         setError(err.message)
      } finally {
         setApplyingChangePatch(false)
      }
   }

   if (queryError) {
      throw queryError
   }

   if (noteLoading || rawBodyLoading) {
      return null
   }

   return (
      <Modal
         borderRadius="5px"
         width="100%[a-b] 800px[c-f]"
         height="90%"
         maxHeight="100%"
         onCloseComplete={modalOnCloseComplete}
         onCloseTrigger={modalOnCloseTrigger}
         open={modalOpen}>
         <View width="100%" height="100%" scroll="on">
            <View
               display="flex"
               justifyContent="space-between"
               alignItems="center"
               height="60px"
               padding="0px 15px"
               borderBottom="1px solid #eee">
               <Text size="2.5" weight="medium">
                  Force Reset Note
               </Text>
               <View
                  display="flex"
                  alignItems="center"
                  padding="5px 0px"
                  onClick={onCloseButtonClick}>
                  <CloseIcon
                     width="20px"
                     height="20px"
                     strokeWidth="2px"
                     color="#aaa"
                  />
               </View>
            </View>
            {error && (
               <NotificationInline
                  textSize="1.5"
                  type="error"
                  title="An error occured"
                  message={error}
               />
            )}
            <View padding="15px">
               <Formik
                  initialValues={{ rawMediumBody: JSON.stringify(data.rawMediumBody, null, 3) }}
                  onSubmit={handleForceReset}
                  render={({ handleSubmit, isSubmitting, resetForm }) => (
                     <Form
                        elementAppearance="plain"
                        elementBackgroundColor="#eee"
                        elementFocusBackgroundColor="#f3f3f3"
                        onSubmit={handleSubmit}>
                        <View>
                           <Text size="1.5" color="#888">
                              {'Force resetting the note will also cause any queued realtime changes to be lost.'}
                              <br />
                              {`Changes pending: ${note.numPendingChanges}`}
                           </Text>
                           <Spacer size="2" />
                           <Textarea name="rawMediumBody" size="sm" style={{ height: '70vh' }} />
                           <Spacer size="2" />
                        </View>
                        <View display="flex" justifyContent="flex-end">
                           <Button
                              name="Apply Next Change Patch"
                              onClick={() => handleApplyNextChangePatch(resetForm)}
                              mode={(() => {
                                 if (isApplyingChangePatch) return 'loading'
                                 if (note.numPendingChanges === 0) return 'disabled'
                                 return 'normal'
                              })()}
                              size="md"
                              textSize="0.9"
                              padding="25px"
                           />
                           <Button
                              type="submit"
                              name="Save"
                              mode={isSubmitting ? 'loading' : 'normal'}
                              textWeight="medium"
                              size="md"
                              textSize="0.9"
                              padding="25px"
                           />
                        </View>
                     </Form>
                  )}
               />
            </View>
         </View>
      </Modal>
   )
}

NoteForceReset.propTypes = {
   modalOnCloseComplete: PropTypes.func.isRequired,
   modalOnCloseTrigger: PropTypes.func.isRequired,
   modalOpen: PropTypes.bool.isRequired,
   noteId: PropTypes.string.isRequired,
   onCloseButtonClick: PropTypes.string.isRequired
}

export default NoteForceReset
