// =========================================================================================@@
// Last Updated Date: Apr 4, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext, useEffect, useState } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link, useRouteMatch } from 'react-router-dom'
import { AddIcon } from 'assets/icons'
import { useMediumList } from 'src/core/graphql/hooks'
import MediumMoreControl from 'src/core/kits/Medium/components/MoreControl'
import MediumTitleEditable from 'src/core/kits/Medium/components/TitleEditable'
import { IconButton } from 'src/core/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import GridView from './GridView'
import FeedView from './FeedView'
import ListView from './ListView'

const Collection = ({ onDelete }) => {
   const match = useRouteMatch()
   const { initiative: medium } = useContext(InitiativeHierarchyContext)
   const sortItemsBy = medium.metadataValues.find(f => f.key === 'sortItemsBy')?.value || 'recent'
   const groupItemsByField = medium.metadataValues.find(f => f.key === 'groupItemsBy')?.value
   const itemsAreGrouped = groupItemsByField && Object.keys(groupItemsByField).length > 0

   const tmpMediumViewModeKey = `${medium.id}.VIEW_MODE`
   const initialViewModeKey = window.localStorage.getItem(tmpMediumViewModeKey) || 'list'
   const [viewMode, setViewMode] = useState(initialViewModeKey)

   useEffect(() => {
      window.localStorage.setItem(tmpMediumViewModeKey, viewMode)
   }, [viewMode])

   const { mediumList } = useMediumList({
      groupBy: groupItemsByField,
      parentInitiativeId: medium?.id,
      sortBy: sortItemsBy
   }, { skip: !medium?.id })

   return (
      <>
         <View
            position="absolute"
            top="0px"
            bottom="0px"
            left="0px"
            right="0px"
            padding="90px 100px"
            backgroundColor="var(--bgColor)"
            scroll="on">
            <View display="flex" justifyContent="center">
               <View
                  width="100%"
                  maxWidth={viewMode === 'feed' && '700px'}>
                  <View
                     display="flex"
                     justifyContent="space-between"
                     alignItems="center"
                     padding="var(--appHeadingHeight) 0px 0px 0px">
                     <MediumTitleEditable
                        id={medium.id}
                        name={medium.name}
                     />
                     <div
                        style={{
                           display: 'flex',
                           alignItems: 'center',
                           gap: '20px'
                        }}>
                        <View flex="0 0 auto" onClick={() => setViewMode('list')}>
                           List
                        </View>
                        <View flex="0 0 auto" onClick={() => setViewMode('feed')}>
                           Feed
                        </View>
                        <View flex="0 0 auto" onClick={() => setViewMode('gridLarge')}>
                           Big Grid
                        </View>
                        <View flex="0 0 auto" onClick={() => setViewMode('gridSmall')}>
                           Small Grid
                        </View>
                        <Link to={`${match.url}/-/create`}>
                           <IconButton>
                              <View display="flex" alignItems="center" opacity="0.5">
                                 <AddIcon
                                    width="20px"
                                    height="20px"
                                    color="var(--textColor)"
                                    strokeWidth="2px"
                                 />
                              </View>
                           </IconButton>
                        </Link>
                     </div>
                  </View>
                  <div
                     style={{
                        width: '100%',
                        height: viewMode === 'grid' && '30px',
                        marginTop: '60px'
                     }}
                  />
                  <View width="100%">
                     {(viewMode === 'gridLarge' || viewMode === 'gridSmall') && (
                        <GridView
                           items={mediumList.items}
                           numColumns={viewMode === 'gridLarge'
                              ? '4[a-e] 5[f]'
                              : '5[a-e] 6[f]'
                           }
                        />
                     )}
                     {viewMode === 'feed' && (
                        <FeedView
                           items={mediumList.items}
                           parentInitiativeId={medium.id}
                        />
                     )}
                     {viewMode === 'list' && (
                        <ListView
                           items={mediumList.items}
                           itemsAreGrouped={itemsAreGrouped}
                        />
                     )}
                  </View>
                  <View
                     display="flex"
                     justifyContent="flex-end"
                     width="100%"
                     height="90px"
                  />
               </View>
            </View>
         </View>
         <View
            display="flex"
            alignItems="center"
            height="var(--toolbarHeight)"
            position="absolute"
            top="5px"
            right="0px"
            padding="0px 20px"
            zIndex="2">
            <MediumMoreControl
               onDelete={onDelete}
               popoverAnchorOriginHorizontal="right"
               popoverAnchorOriginVertical="top"
            />
         </View>
      </>
   )
}

Collection.propTypes = {
   onDelete: PropTypes.func.isRequired
}

export default Collection
