// =========================================================================================@@
// Last Updated Date: Feb 17, 2022
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import { useMutation } from '@apollo/client'
import { updateMetadataField as updateMetadataFieldGql }
   from 'src/core/graphql/mutations/updateMetadataField.gql'

const useUpdateMetadataField = (mutationHookOptions) => {
   const [updateMetadataField, { called, client, data, error, loading }] =
      useMutation(updateMetadataFieldGql, mutationHookOptions)

   const wrappedMutationFn = (filter, input, mutationFnOptions) => updateMetadataField({
      variables: { ...filter, input },
      ...mutationFnOptions
   })

   return {
      updateMetadataField: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useUpdateMetadataField
