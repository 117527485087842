import React, { useContext } from 'react'
import { Modal } from 'oio-react'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { useInitiativeList, useOrganization } from 'src/core/graphql/hooks'
import MediumCreate from 'src/core/kits/Medium/apps/Create'
import MediumCreatePrompt from 'src/core/kits/Medium/apps/CreatePrompt'
import MediumInfo from 'src/core/kits/Medium/apps/Info'
import MediumMove from 'src/core/kits/Medium/apps/Move'
import MediumRules from 'src/core/kits/Medium/apps/Rules'
import MediumStart from 'src/core/kits/Medium/apps/Start'
import MediumView from 'src/core/kits/Medium/apps/View' // eslint-disable-line import/no-cycle
import NoteForceReset from 'src/core/kits/Medium/components/NoteForceReset'
import Search from 'src/core/kits/Search'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const MediumViewRoutes = () => {
   const history = useHistory()
   const match = useRouteMatch()

   const { organization } = useOrganization()
   const mediumType = organization.initiativeTypes.find(t => t.class === 'medium')

   const { initiative: medium } = useContext(InitiativeHierarchyContext)
   const { initiativeList: mediumList } = useInitiativeList({
      typeIds: mediumType.id,
      parentInitiativeId: medium ? medium?.id : undefined,
      limit: 50,
      sortBy: 'curated'
   }, { skip: !medium?.id })

   const isMultiViewMedium = ['sidebar', 'tabs'].includes(medium.protoFormat)

   return (
      <Switch>
         {/* APP ROUTES */}
         {isMultiViewMedium && mediumList.items.length === 0 && (
            <Route exact path={match.url} component={MediumStart} />
         )}
         {isMultiViewMedium && mediumList.items.length > 0 && (
            <Redirect exact from={match.url} to={`${mediumList.items[0].gatherUrl}`} />
         )}
         {isMultiViewMedium && (
            <Route
               path={`${match.path}/-/search`}
               component={Search}
            />
         )}
         {isMultiViewMedium && (
            <Route
               path={`${match.path}/-/create`}
               render={() => (
                  <MediumCreate
                     onCreate={newPage => history.push(newPage.gatherUrl)}
                  />
               )}
            />
         )}

         {/* COMMON ROUTES */}
         <ModalRoute path={`${match.path}/-/debug`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <NoteForceReset
                  noteId={medium.id}
                  modalOnCloseComplete={onCloseComplete}
                  modalOnCloseTrigger={onCloseTrigger}
                  modalOpen={open}
                  onCloseButtonClick={onCloseTrigger}
               />
            )}
         </ModalRoute>
         <ModalRoute path={`${match.path}/-/info`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <MediumInfo
                  modalOnCloseComplete={onCloseComplete}
                  modalOnCloseTrigger={onCloseTrigger}
                  modalOpen={open}
               />
            )}
         </ModalRoute>
         <ModalRoute path={`${match.path}/-/rules`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <MediumRules
                  id={medium.id}
                  modalOnCloseComplete={onCloseComplete}
                  modalOnCloseTrigger={onCloseTrigger}
                  modalOpen={open}
               />
            )}
         </ModalRoute>
         {/* TODO: Not sure how to handle this - maybe query variables instead? #PR 1128 */}
         <ModalRoute path={`${match.path}/-/move/:destinationMediumId?/:destinationMediumName?`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <MediumMove
                  modalOnCloseComplete={onCloseComplete}
                  modalOnCloseTrigger={onCloseTrigger}
                  modalOpen={open}
                  onMove={() => {
                     onCloseTrigger()
                     history.push(match.url)
                  }}
               />
            )}
         </ModalRoute>

         {!isMultiViewMedium && (
            <ModalRoute path={`${match.path}/-/create`}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <MediumCreatePrompt
                     modalOnCloseComplete={onCloseComplete}
                     modalOnCloseTrigger={onCloseTrigger}
                     modalOpen={open}
                     modalWidth="300px[a-b] 480px[c-f]"
                     parentInitiativeId={medium.id}
                     onCreate={onCloseTrigger}
                  />
               )}
            </ModalRoute>
         )}

         {/* APP VERSION */}
         {isMultiViewMedium && (
            <Route
               path={`${match.url}/:mediumSlug([a-zA-Z0-9][^/]+)`}
               render={() => (
                  <MediumView
                     onDelete={() => {
                        // TODO: Fix: This currently has issues if the initiative being deleted
                        // is the first in the pageList array
                        // (since it will automatically redirect) - PR #1054
                        history.push(match.url)
                     }}
                  />
               )}
            />
         )}
         {/* NON APP */}
         {!isMultiViewMedium && (
            <ModalRoute path={`${match.url}/:mediumSlug([a-zA-Z0-9][^/]+)`}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <Modal
                     borderRadius="5px"
                     width="100%[a-d] 1100px[e-f]"
                     height="100%"
                     onCloseComplete={onCloseComplete}
                     onCloseTrigger={onCloseTrigger}
                     open={open}
                     zIndex="1">
                     <div className="ui-modal">
                        <MediumView
                           modalOnCloseComplete={onCloseComplete}
                           modalOnCloseTrigger={onCloseTrigger}
                           modalOpen={open}
                           onDelete={() => history.push(match.url)}
                        />
                     </div>
                  </Modal>
               )}
            </ModalRoute>
         )}
      </Switch>
   )
}

export default MediumViewRoutes
