// =========================================================================================@@
// Last Updated Date: Mon Feb 07 2022
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { Modal, View } from 'oio-react'
import PropTypes from 'prop-types'
import { ListMenuButton, SidebarLayout } from 'src/core/kits/UI'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import People from 'src/sites/kits/AdminWorkspace/apps/People'

const AppSettings = ({
   modalOnCloseComplete,
   modalOnCloseTrigger,
   modalOpen
}) => {
   const match = useRouteMatch()

   return (
      <Modal
         width="900px"
         height="600px"
         onCloseComplete={modalOnCloseComplete}
         onCloseTrigger={modalOnCloseTrigger}
         open={modalOpen}>
         <View className="ui-modal" width="100%" height="100%">
            <SidebarLayout.Container>
               <SidebarLayout.Nav>
                  <View padding="20px">
                     <ListMenuButton
                        linkTo={`${match.url}/people`}
                        name="People"
                     />
                  </View>
               </SidebarLayout.Nav>
               <SidebarLayout.Main>
                  <View width="100%" height="100%" scroll="on">
                     <Switch>
                        <Redirect exact from={match.url} to={`${match.path}/people`} />
                        <Route path={`${match.path}/people`} component={People} />
                     </Switch>
                  </View>
               </SidebarLayout.Main>
            </SidebarLayout.Container>
         </View>
      </Modal>
   )
}

AppSettings.propTypes = {
   modalOnCloseComplete: PropTypes.func.isRequired,
   modalOnCloseTrigger: PropTypes.func.isRequired,
   modalOpen: PropTypes.bool.isRequired
}

export default AppSettings
