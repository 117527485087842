// =========================================================================================@@
// Last Updated Date: Mar 22, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// Pure Prototype component
// ===========================================================================================

import React, { useRef, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link, useRouteMatch } from 'react-router-dom'
import { CloseIcon } from 'assets/icons'
import { searchInitiatives } from 'src/core/graphql/queries/searchInitiatives.gql'

const LiveTokenInput = ({ defaultValue, onChange }) => {
   const tokenInput = useRef()
   const match = useRouteMatch()
   const client = useApolloClient()
   const [searchResults, setSearchResults] = useState([])
   const [showAutocompleteMenu, setShowAutoCompleteMenu] = useState(false)

   const search = async (searchValue) => {
      const result = await client.query({
         query: searchInitiatives,
         variables: { search: searchValue, mode: 'autocomplete' }
      })

      setSearchResults(result?.data?.searchInitiatives.items)
   }

   const handleLiveTokenSelection = (newValue) => {
      onChange(newValue)
      tokenInput.current.value = ''
      setShowAutoCompleteMenu(false)
   }

   return (
      <View position="relative" backgroundColor="#eee">
         <View display="flex">
            <View flex="0 0 auto">
               {defaultValue.map(value => (
                  <View
                     key={value.id}
                     display="flex"
                     backgroundColor="#aaa"
                     padding="0px 4px"
                     borderRadius="4px">
                     {/* TODO: This link is not always reliable as it is relative. PR#1127 */}
                     <Link
                        to={`${match.url}/${value.slug}`}
                        style={{ color: 'var(--colorText)' }}>
                        <u>{value.name}</u>
                     </Link>
                     <View
                        display="inline"
                        flex="0 0 auto"
                        marginLeft="6px"
                        onClick={() => handleLiveTokenSelection([])}>
                        <CloseIcon width="12px" height="12px" />
                     </View>
                  </View>
               ))}
            </View>
            <input
               ref={tokenInput}
               onChange={event => search(event.target.value)}
               onFocus={() => setShowAutoCompleteMenu(true)}
               style={{ backgroundColor: 'transparent' }}
            />
         </View>
         {showAutocompleteMenu && (
            <View
               position="absolute"
               top="30px"
               left="0px"
               width="200px"
               maxHeight="200px"
               backgroundColor="#eee"
               scroll="on"
               zIndex="var(--zIndexAutocompleteMenu)">
               {searchResults?.map(result => (
                  <View
                     key={result.value.id}
                     onClick={() => {
                        handleLiveTokenSelection([{
                           id: result.value.id,
                           name: result.value.name,
                           slug: result.value.slug
                        }])
                     }}>
                     {result.value.name}
                  </View>
               ))}
               <View onClick={() => setShowAutoCompleteMenu(false)}>
                  Close
               </View>
            </View>
         )}
      </View>
   )
}

LiveTokenInput.propTypes = {
   defaultValue: PropTypes.array,
   onChange: PropTypes.func.isRequired
}

LiveTokenInput.defaultProps = {
   defaultValue: []
}

export default LiveTokenInput
