// TODO: Make this component generic and remove need for TitleEditable component
// PR #1097

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash-es'
import PropTypes from 'prop-types'

const EditInPlaceInput = ({
   defaultValue,
   onDebouncedChange,
   placeholder
}) => {
   const titleInputRef = useRef(null)
   const [inputValue, setInputValue] = useState('')

   const handleInputValueChangeDebounced = useCallback(debounce(() => {
      const newInputValue = titleInputRef.current.value
      onDebouncedChange(newInputValue)
   }, 2000), [])

   const handleInputValueChange = (e) => {
      handleInputValueChangeDebounced()
      setInputValue(e.currentTarget.value)
   }

   useEffect(() => {
      if (typeof defaultValue === 'string') {
         setInputValue(defaultValue)
      }
   }, [defaultValue])

   return (
      <div style={{ display: 'inline-grid', alignItems: 'stretch' }}>
         <textarea
            ref={titleInputRef}
            onChange={handleInputValueChange}
            placeholder={placeholder}
            value={inputValue}
            style={{
               gridArea: '1 / 1 / 2 / 2',
               padding: 0,
               border: 'none',
               position: 'relative',
               width: '100%',
               left: '-2px',
               color: 'inherit',
               backgroundColor: 'transparent',
               fontFamily: 'inherit',
               fontSize: 'inherit',
               fontWeight: 'inherit',
               letterSpacing: 'inherit',
               lineHeight: 'inherit',
               textTransform: 'inherit',
               resize: 'none',
               overflow: 'hidden'
            }}
            rows="1"
         />
         <span
            style={{
               gridArea: '1 / 1 / 2 / 2',
               visibility: 'hidden',
               whiteSpace: 'pre-wrap'
            }}>
            {`${inputValue} `}
         </span>
      </div>
   )
}

EditInPlaceInput.propTypes = {
   defaultValue: PropTypes.string,
   onDebouncedChange: PropTypes.func.isRequired,
   placeholder: PropTypes.string
}

EditInPlaceInput.defaultProps = {
   defaultValue: undefined,
   placeholder: 'Empty'
}

export default EditInPlaceInput
