// =========================================================================================@@
// Last Updated Date: Feb 13, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { Link } from 'react-router-dom'
import { SearchIcon } from 'assets/icons'
import { useInitiativeList, useOrganization } from 'src/core/graphql/hooks'
import MediumCreateControl from 'src/core/kits/Medium/components/CreateControl'
import { ListMenuButton, Title } from 'src/core/kits/UI'
import AppMoreControl from '../MoreControl'

const AppNav = ({ orientation }) => {
   const { organization } = useOrganization()
   const mediumType = organization.initiativeTypes.find(t => t.class === 'medium')

   const { initiativeList: mediumList } = useInitiativeList({
      typeIds: mediumType.id,
      limit: 50,
      sortBy: 'curated'
   })

   if (orientation === 'horizontal') {
      return (
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="100%">
            <View
               display="flex"
               alignItems="center"
               flex="0 0 auto"
               width="300px"
            />
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               width="300px"
               textAlign="right"
               marginLeft="10px">
               <MediumCreateControl iconSize="18px" />
               <Link to="/-/search">
                  <View
                     display="flex"
                     alignItems="center"
                     paddingTop="1px"
                     marginLeft="20px"
                     opacity="0.5">
                     <SearchIcon width="16px" height="16px" color="var(--textColor)" />
                  </View>
               </Link>
            </View>
         </View>
      )
   }

   return (
      <View
         position="relative"
         width="100%"
         height="100%">
         <div
            style={{
               display: 'flex',
               justifyContent: 'space-between',
               alignItems: 'center',
               height: 'var(--toolbarHeight)',
               padding: '0px 15px',
               position: 'absolute',
               top: '5px',
               right: '0px'
            }}>
            <AppMoreControl
               popoverAnchorOriginHorizontal="right"
               popoverAnchorOriginVertical="top"
            />
         </div>
         <View
            width="100%"
            height="calc(100% - var(--toolbarHeight))"
            padding="0px 15px"
            scroll="on">
            <View
               height="var(--appHeadingHeight)"
               paddingTop="var(--viewportMarginTop)"
            />
            <View
               flex="0 0 auto"
               width="30px"
               height="30px"
               border="1px solid var(--formFieldBackgroundColor)"
               backgroundColor="var(--formFieldBackgroundColor)"
               borderRadius="6px"
               marginTop="5px"
               marginBottom="10px"
               marginRight="15px"
            />
            <View padding="20px 5px">
               <Title size="sm">{organization.name}</Title>
            </View>
            <View marginBottom="10px">
               <Link to="/-/search">
                  <View
                     display="flex"
                     alignItems="center"
                     height="var(--buttonHeight)"
                     padding="0px 10px"
                     borderRadius="6px"
                     opacity="0.7"
                     backgroundColor="var(--formFieldBackgroundColor)">
                     <View
                        display="flex"
                        alignItems="center"
                        paddingBottom="2px"
                        marginRight="10px"
                        opacity="0.5">
                        <SearchIcon width="12px" height="12px" color="var(--textColor)" />
                     </View>
                     <View marginTop="1px" opacity="0.4" style={{ color: 'var(--textColor)' }}>
                        Search
                     </View>
                  </View>
               </Link>
            </View>
            <Droppable droppableId="root-mediums">
               {(pageDroppableProvided, droppableProvidedSnapshot) => (
                  <div ref={pageDroppableProvided.innerRef}>
                     {mediumList.items.map((page, index) => (
                        <Draggable
                           key={page.id}
                           draggableId={page.id}
                           index={index}>
                           {(pageProvided, pageSnapshot) => (
                              <div
                                 ref={pageProvided.innerRef}
                                 {...pageProvided.draggableProps}
                                 {...pageProvided.dragHandleProps}
                                 style={pageProvided.draggableProps.style}>
                                 <ListMenuButton
                                    key={page.id}
                                    linkTo={page.gatherUrl}
                                    name={page.name}
                                    showDivider={page.protoFormat === 'cover'}
                                 />
                              </div>
                           )}
                        </Draggable>
                     ))}
                     {pageDroppableProvided.placeholder}
                  </div>
               )}
            </Droppable>
         </View>
         <View
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width="100%"
            height="var(--toolbarHeight)"
            padding="0px 15px">
            <MediumCreateControl />
         </View>
      </View>
   )
}

AppNav.propTypes = {
   orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired
}

export default AppNav
