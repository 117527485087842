// =========================================================================================@@
// Last Updated Date: Feb 17, 2022
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import { useMutation } from '@apollo/client'
import { removeMetadataField as removeMetadataFieldGql }
   from 'src/core/graphql/mutations/removeMetadataField.gql'

const useRemoveMetadataField = (mutationHookOptions = {}) => {
   const [removeMetadataField, { called, client, data, error, loading }] = useMutation(
      removeMetadataFieldGql,
      { refetchQueries: ['getMetadataFieldList'], ...mutationHookOptions }
   )

   const wrappedMutationFn = (variables, mutationFnOptions) => removeMetadataField({
      variables,
      ...mutationFnOptions
   })

   return {
      removeMetadataField: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useRemoveMetadataField
