// =========================================================================================@@
// Last Updated Date: Feb 10, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { useInitiativeList, useOrganization } from 'src/core/graphql/hooks'
import AppSettings from 'src/core/kits/App/apps/Settings'
import AppNav from 'src/core/kits/App/components/Nav'
import { MediumCreate, MediumMove, MediumStart, MediumView } from 'src/core/kits/Medium'
import Search from 'src/core/kits/Search'
import Trash from 'src/core/kits/Trash'
import { SidebarLayout } from 'src/core/kits/UI'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'

// TODO: Much of this code will be folded into MediumView
// But we keep this for now until we sort out exactl how organizations will map to "apps"
// PR #1054
const AppView = () => {
   const history = useHistory()
   const match = useRouteMatch()

   const { organization } = useOrganization()
   const mediumType = organization.initiativeTypes.find(t => t.class === 'medium')
   const { initiativeList: mediumList, networkStatus } = useInitiativeList({
      typeIds: mediumType.id,
      limit: 50,
      sortBy: 'curated'
   })

   const initialLoading = networkStatus <= 2

   return (
      <SidebarLayout.Container>
         <SidebarLayout.Nav mode="root">
            <AppNav orientation="vertical" />
         </SidebarLayout.Nav>
         <SidebarLayout.Main mode="root">
            <Switch>
               {!initialLoading && mediumList.items.length === 0 && (
                  <Route exact path={match.url} component={MediumStart} />
               )}
               <Route
                  path="/-/create"
                  render={() => (
                     <MediumCreate onCreate={newPage => history.push(newPage.gatherUrl)} />
                  )}
               />
               <ModalRoute path="/-/admin">
                  {({ open, onCloseComplete, onCloseTrigger }) => (
                     <AppSettings
                        modalOnCloseComplete={onCloseComplete}
                        modalOnCloseTrigger={onCloseTrigger}
                        modalOpen={open}
                     />
                  )}
               </ModalRoute>
               <ModalRoute path="/-/move">
                  {({ open, onCloseComplete, onCloseTrigger }) => (
                     <MediumMove
                        modalOnCloseComplete={onCloseComplete}
                        modalOnCloseTrigger={onCloseTrigger}
                        modalOpen={open}
                        onMove={() => history.push(match.url)}
                     />
                  )}
               </ModalRoute>
               <ModalRoute path="/-/trash">
                  {({ open, onCloseComplete, onCloseTrigger }) => (
                     <Trash
                        modalOnCloseComplete={onCloseComplete}
                        modalOnCloseTrigger={onCloseTrigger}
                        modalOpen={open}
                     />
                  )}
               </ModalRoute>
               <Route
                  path="/-/search"
                  component={Search}
               />
               <Route
                  path="/:mediumSlug([a-zA-Z0-9][^/]+)"
                  render={() => <MediumView onDelete={() => history.push('/')} />}
               />
            </Switch>
         </SidebarLayout.Main>
      </SidebarLayout.Container>
   )
}

export default AppView
