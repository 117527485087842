import React from 'react'
import PropTypes from 'prop-types'

const TOCHideIcon = ({ className, fill, width }) => (
   <svg
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={fill}
         stroke={fill}
         strokeLinecap="square"
         strokeLinejoin="miter"
         strokeMiterlimit="10"
         strokeWidth="2">
         <line fill="none" strokeLinecap="butt" x1="23" x2="7" y1="12" y2="12" />
         <line fill="none" x1="2" x2="2" y1="2" y2="22" />
         <polyline fill="none" points="13,6 7,12 13,18 " />
      </g>
   </svg>
)

TOCHideIcon.propTypes = {
   className: PropTypes.string,
   fill: PropTypes.string,
   width: PropTypes.string
}

TOCHideIcon.defaultProps = {
   className: undefined,
   fill: '#ccc',
   width: '10px'
}

export default TOCHideIcon
