// =========================================================================================@@
// Last Updated Date: Feb 17, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import React, { useContext, useEffect, useRef, useState } from 'react'
import ContentEditorV3 from '@mother/content'
import { css, keyframes } from 'emotion'
import { Button, View, Textarea } from 'oio-react'
import PropTypes from 'prop-types'
import { useCreateInitiative, useMe, useOrganization } from 'src/core/graphql/hooks'
import { Title } from 'src/core/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const animateIn = keyframes`
   0% { opacity: 0; transform: translate3d(0, 100px, 0); }
   100% { opacity: 1; transform: translate3d(0, 0, 0); }
`

const mediumFormats = ['page', 'cover', 'grid', 'sidebar']
const initialValue = [{
   type: 'p',
   children: [{ text: '' }]
}]

const MediumCreate = ({ onCreate }) => {
   const nameInputRef = useRef(null)
   const [selectedMediumFormat, setSelectedMediumFormat] = useState('page')
   const [body, setBody] = useState(initialValue)

   const { initiative: parentMedium } = useContext(InitiativeHierarchyContext)
   const { createInitiative, mutating: creating } = useCreateInitiative()
   const { organization } = useOrganization()
   const mediumType = organization.initiativeTypes.find(t => t.class === 'medium')

   const { me } = useMe()
   const isGatherDeveloper = me.email.endsWith('@mother.co')

   const handleCreate = async () => {
      const result = await createInitiative({
         bodyV3: body,
         metadataValues: [{ key: 'format', value: selectedMediumFormat }],
         name: nameInputRef.current.value,
         parentInitiativeId: parentMedium?.id,
         privacy: 'inherit',
         typeId: mediumType.id
      })

      if (onCreate) {
         onCreate(result.data.createInitiative)
      }
   }

   useEffect(() => {
      nameInputRef.current.focus()
   }, [])

   return (
      <View position="relative" width="100%" height="100%">
         <View
            display="flex"
            justifyContent="flex-end"
            height="var(--appHeadingHeight)"
            padding="30px">
            <Button
               width="78px"
               onClick={handleCreate}
               color="var(--textColor)"
               size="xs[a-b] sm[c-f]"
               mode={creating ? 'loading' : 'normal'}
               name="Save"
               rounded
            />
         </View>
         <View
            display="flex"
            justifyContent="center"
            width="100%">
            <View width="100%" maxWidth="650px">
               <Title size="lg">
                  <Textarea
                     ref={nameInputRef}
                     appearance="plain"
                     size="inherit"
                     name="title"
                     placeholder="Name"
                     rows="1"
                     style={{
                        position: 'relative',
                        width: '100%',
                        left: '-10px',
                        color: '#333',
                        backgroundColor: 'transparent',
                        fontFamily: 'inherit',
                        fontSize: 'inherit',
                        fontWeight: 'inherit',
                        letterSpacing: 'inherit',
                        resize: 'none'
                     }}
                  />
               </Title>
               <div style={{ width: '100%', height: '40px' }} />
               <View className="ui-paragraph-text">
                  <ContentEditorV3
                     placeholder="Click here to start writing..."
                     initialValue={initialValue}
                     onChange={setBody}
                  />
               </View>
            </View>
         </View>
         {isGatherDeveloper && (
            <View
               className={css`animation: ${animateIn} 400ms ease;`}
               display="flex"
               justifyContent="center"
               position="absolute"
               bottom="0px"
               left="0px"
               right="0px"
               padding="50px 30px">
               <View
                  width="100%"
                  maxWidth="650px"
                  backgroundColor="var(--buttonActiveBackgroundColor)"
                  opacity="0.5"
                  padding="20px"
                  borderRadius="10px"
                  textAlign="center">
                  <b style={{ color: 'var(--buttonActiveTextColor)' }}>Select Medium</b>
                  <div
                     style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '5px',
                        marginTop: '5px'
                     }}>
                     {mediumFormats.map(format => (
                        <View
                           key={format}
                           onClick={() => setSelectedMediumFormat(format)}
                           padding="2px 10px"
                           borderRadius="4px"
                           backgroundColor={selectedMediumFormat === format && '#aaa'}
                           style={{ color: '#111', textTransform: 'capitalize' }}>
                           {format}
                        </View>
                     ))}
                  </div>
               </View>
            </View>
         )}
      </View>
   )
}

MediumCreate.propTypes = {
   onCreate: PropTypes.func.isRequired
}

export default MediumCreate
