import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const MediumListItemFeedRow = ({ dateLastUpdated, linkTo, name }) => {
   return (
      <Link to={linkTo}>
         <View
            display="flex"
            alignItems="center"
            width="100%"
            padding="10px 0px"
            borderBottom="1px solid var(--borderColor)"
            style={{ gap: '20px', color: 'var(--textColor)' }}>
            <div
               style={{
                  flex: '0 0 auto',
                  width: '90px',
                  color: 'var(--metaColor)'
               }}>
               {dateLastUpdated}
            </div>
            <View flex="1 1 auto">
               <span style={{ color: 'var(--titleColor)', fontWeight: '600' }}>{name}</span>
            </View>
         </View>
      </Link>
   )
}

MediumListItemFeedRow.propTypes = {
   dateLastUpdated: PropTypes.string.isRequired,
   linkTo: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired
}

export default MediumListItemFeedRow
