import React from 'react'
import { Grid, GridCell, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link, useRouteMatch } from 'react-router-dom'
import MediumListItemCard from 'src/core/kits/Medium/components/ListItemCard'

const CollectionGridView = ({ items, numColumns }) => {
   const match = useRouteMatch()

   return (
      <View marginTop="25px">
         <Grid columns={numColumns} spacing="25px">
            {items.map(i => (
               <GridCell key={i.id}>
                  <MediumListItemCard
                     coverUrl={i.coverMedia?.file.thumbnailUrlW480}
                     dateLastUpdated={i.dateLastUpdated}
                     linkTo={i.gatherUrl}
                     name={i.name}
                     tags={i.tags}
                  />
               </GridCell>
            ))}
            <GridCell>
               <Link to={`${match.url}/-/create`}>
                  <View
                     width="100%"
                     paddingTop="60%"
                     borderRadius="2px"
                     backgroundColor="var(--formFieldBackgroundColor)"
                  />
                  <View className="ui-paragraph-text" padding="10px 0px">
                     + New
                  </View>
               </Link>
            </GridCell>
         </Grid>
      </View>
   )
}

CollectionGridView.propTypes = {
   items: PropTypes.array.isRequired,
   numColumns: PropTypes.string
}

CollectionGridView.defaultProps = {
   numColumns: '5'
}

export default CollectionGridView
