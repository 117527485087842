// =========================================================================================@@
// Last Updated Date: Feb 10, 2022
// Last Updated By: Ajay
// Status Level: 1
// This component is highly subject to change
// ===========================================================================================

import React from 'react'
import PropTypes from 'prop-types'
import { useRouteMatch } from 'react-router-dom'
import { useMe } from 'src/core/graphql/hooks'
import { IconButton, ListMenuButton } from 'src/core/kits/UI'
import Popover from 'src/sites/kits/Utils/Popover'

const dotProps = {
   width: '2px',
   height: '2px',
   backgroundColor: 'var(--textColor)',
   borderRadius: '50%'
}

const listMenuButtonProps = {
   height: '30px',
   iconSpacing: '10px'
}

const DefaultButtonComponent = () => (
   <IconButton>
      <div
         style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'row',
            opacity: 0.8,
            width: '18px',
            height: '18px',
            border: '1px solid var(--textColor)',
            borderRadius: '50%',
            zIndex: 3
         }}>
         <div style={{ ...dotProps, marginRight: '2px' }} />
         <div style={{ ...dotProps, marginRight: '2px' }} />
         <div style={{ ...dotProps }} />
      </div>
   </IconButton>
)

const AppMoreControl = ({
   buttonComponent,
   popoverAnchorOriginHorizontal,
   popoverAnchorOriginVertical
}) => {
   const match = useRouteMatch()
   const { me } = useMe()
   const isGatherDeveloper = me?.email.endsWith('@mother.co')

   return (
      <Popover.Provider>
         <Popover.Anchor>
            {buttonComponent}
         </Popover.Anchor>
         <Popover.View
            anchorOriginHorizontal={popoverAnchorOriginHorizontal}
            anchorOriginVertical={popoverAnchorOriginVertical}
            backgroundColor="transparent"
            borderRadius="4px"
            width="150px">
            <div className="ui-popover">
               <ListMenuButton
                  {...listMenuButtonProps}
                  linkTo={`${match.url}/-/move`}
                  name="Move"
               />
               <ListMenuButton
                  {...listMenuButtonProps}
                  linkTo="/-/trash"
                  name="Trash"
               />
               <ListMenuButton
                  {...listMenuButtonProps}
                  linkTo="/-/admin"
                  name="App Settings"
               />
               {isGatherDeveloper && (
                  <ListMenuButton
                     {...listMenuButtonProps}
                     linkTo="/-/ops"
                     name="Ops Centre"
                  />
               )}
            </div>
         </Popover.View>
      </Popover.Provider>
   )
}

AppMoreControl.propTypes = {
   buttonComponent: PropTypes.node,
   popoverAnchorOriginHorizontal: PropTypes.string,
   popoverAnchorOriginVertical: PropTypes.string
}

AppMoreControl.defaultProps = {
   buttonComponent: <DefaultButtonComponent />,
   popoverAnchorOriginHorizontal: 'left',
   popoverAnchorOriginVertical: 'bottom'
}

export default AppMoreControl
