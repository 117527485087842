import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import uiConstants from 'config/constants/ui'
import { useInitiativeList, useOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/core/state'
import CoverPage from 'src/core/kits/Medium/apps/CoverPage'
import { Title } from 'src/core/kits/UI'
import Note from 'src/core/kits/Medium/apps/Note'
import MediumCreateControl from 'src/core/kits/Medium/components/CreateControl'
import MediumMoreControl from 'src/core/kits/Medium/components/MoreControl'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { calcRenderedNotes, determineNoteIndex, nextIndex, prevIndex } from './utils'

const Notebook = ({ onDelete }) => {
   const { useFacingPages, setVisibleNoteIds /* , visibleNoteIds */ } = useGlobalState()

   const { organization } = useOrganization()
   const mediumType = organization.initiativeTypes.find(t => t.class === 'medium')

   const { initiative: medium } = useContext(InitiativeHierarchyContext)
   const { initiativeList } = useInitiativeList({
      typeIds: mediumType.id,
      parentInitiativeId: medium?.id,
      limit: 50,
      sortBy: 'curated'
   }, { skip: !medium?.id })

   const notes = initiativeList.items

   // currentIndex: If using facing pages, currentIndex should always be the left note
   //               (which is an even index)
   // notesRendered: This state prop stores which notes are rendered to the DOM
   //                (based on the current index)
   const [[noteId, currentIndex, notesRendered], setNotebookMeta] =
      useState([1, null, []])

   // Number of notes to be rendered on the DOM
   const numNotesRendered = useFacingPages ? 10 : 8

   const noteIds = useRef(notes.map(n => n.id))

   // Initialize notebook metadata
   useEffect(() => {
      noteIds.current = notes.map(n => n.id)
      const newCurrentIndex = noteId
         ? determineNoteIndex(noteIds.current, noteId, useFacingPages)
         : 0

      setNotebookMeta([
         noteId,
         newCurrentIndex,
         notesRendered
      ])
   }, [notes])

   // If the note id param changes in the url, then figure out the appropriate
   // note index.
   useEffect(() => {
      const newCurrentIndex = null // noteIdParam /*noteIdParam */
         ? determineNoteIndex(noteIds.current, null, useFacingPages)
         : 0

      setNotebookMeta([
         null, // noteIdParam,
         newCurrentIndex,
         calcRenderedNotes(newCurrentIndex, notes, numNotesRendered)
      ])
   }, [notes/* noteIdParam */])

   // Figure out which note(s) are currently being displayed and update
   // the global context.
   useEffect(() => {
      const newVisibleNoteIds = [notes[currentIndex]?.id]
      if (useFacingPages) {
         const adjacentNoteId = currentIndex % 2 === 0
            ? notes[currentIndex + 1]?.id
            : notes[currentIndex - 1]?.id

         if (adjacentNoteId) {
            newVisibleNoteIds.push(adjacentNoteId)
         }
      }

      setVisibleNoteIds(newVisibleNoteIds)
   }, [currentIndex, notes, useFacingPages])

   // Keyboard Events
   const handleKeydown = useCallback((e) => {
      const tag = e.target.tagName.toLowerCase()
      const isContentEditable = e.target.contentEditable === 'true'
      if (isContentEditable || tag === 'input' || tag === 'textarea') {
         return
      }

      if (e.keyCode === 37) {
         // Left
         e.stopPropagation()
         const newIndex = prevIndex(currentIndex, useFacingPages)
         const newNoteId = noteIds.current[newIndex]

         setNotebookMeta([
            newNoteId,
            newIndex,
            calcRenderedNotes(newIndex, notes, numNotesRendered)
         ])
      } else if (e.keyCode === 39) {
         // Right
         e.stopPropagation()
         const newIndex = nextIndex(currentIndex, notes.length, useFacingPages)
         const newNoteId = noteIds.current[newIndex]

         setNotebookMeta([
            newNoteId,
            newIndex,
            calcRenderedNotes(newIndex, notes, numNotesRendered)
         ])
      }
   }, [notes, currentIndex, useFacingPages])

   useEffect(() => {
      window.addEventListener('keydown', handleKeydown)

      // Remove event listeners on component unmount
      return () => {
         window.removeEventListener('keydown', handleKeydown)
      }
   }, [handleKeydown])

   const notesRenderedIds = notesRendered.map(n => n.id)

   return (
      <>
         <div
            style={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>
            {notes.map((note, index) => {
               const isCoverPage = note.protoFormat === 'cover'
               const noteIndex = noteIds.current.indexOf(note.id)
               const facingLeft = useFacingPages && noteIndex % 2 === 0
               const facingRight = useFacingPages && noteIndex % 2 === 1
               const isFacingPage = facingLeft || facingRight
               const offscreen = noteIndex < currentIndex
               const rendered = notesRenderedIds.includes(note.id)
               // const cachedNoteData = client.readFragment({
               //    id: `Initiative:${note.id}`,
               //    fragment: gql`
               //       fragment NoteName on Initiative {
               //          id
               //          class
               //          name
               //       }
               //    `
               // })

               return (
                  <div
                     id={`${note.id}`}
                     key={note.id}
                     className={`
                        gather-notebook-page
                        ${(facingLeft || facingRight) ? 'facing-page' : 'full-spread-page'}
                        ${facingLeft ? 'facing-left' : ''}
                        ${facingRight ? 'facing-right' : ''}
                        ${offscreen ? 'offscreen' : 'onscreen'}
                     `}
                     style={{
                        zIndex: uiConstants.zIndexes.noteStack - index - 1,
                        boxShadow: (facingRight || !isFacingPage) && '0 0 30px rgba(0,0,0,0.2)',
                        display: rendered ? 'block' : 'none'
                     }}>
                     {isCoverPage && rendered && (
                        <CoverPage
                           id={note.id}
                           name={note.name}
                           onDelete={onDelete}
                        />
                     )}
                     {!isCoverPage && rendered && (
                        <Note
                           body={note?.body}
                           bodyVersion={note?.body?.v}
                           dateLastUpdated={note.dateLastUpdated}
                           id={note.id}
                           name={note.name}
                           onDelete={onDelete}
                        />
                     )}
                  </div>
               )
            })}
         </div>
         <div
            style={{
               display: 'flex',
               justifyContent: 'space-between',
               alignItems: 'center',
               height: 'var(--toolbarHeight)',
               padding: '0px 15px',
               position: 'absolute',
               bottom: '0px',
               left: '0px',
               zIndex: uiConstants.zIndexes.noteStack + 99
            }}>
            <MediumCreateControl iconSize="18px" />
            <MediumMoreControl
               buttonComponent={(<Title size="sm">{medium.name}</Title>)}
               titleSize="sm"
               popoverAnchorOriginVertical="bottom"
            />
         </div>
      </>
   )
}

Notebook.propTypes = {
   onDelete: PropTypes.func.isRequired
}

export default Notebook
