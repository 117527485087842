import React from 'react'
import { View } from 'oio-react'
import { GridViewGlyph16Icon } from 'assets/icons'
import { IconButton, TOCHideIcon } from 'src/core/kits/UI'
import { useGlobalState } from 'src/core/state'

// const lineProps = {
//    width: '16px',
//    height: '2px',
//    backgroundColor: 'var(--textColor)',
//    opacity: '0.5'
// }

const TOCToggleControl = () => {
   const { setTocDisplayMode, tocDisplayMode } = useGlobalState()

   if (tocDisplayMode === 'pinned') {
      return (
         <IconButton onClick={() => setTocDisplayMode('hidden')}>
            <div style={{ display: 'flex', alignItems: 'center', opacity: 0.5 }}>
               <TOCHideIcon fill="var(--textColor)" width="20px" />
            </div>
         </IconButton>
      )
   }

   return (
      <IconButton onClick={() => setTocDisplayMode('overlay')}>
         <View
            display="flex"
            justifyContent="center"
            alignItems="center"
            opacity="0.5">
            <GridViewGlyph16Icon color="var(--textColor)" />
         </View>
         {/* <div style={{ ...lineProps, marginBottom: '4px' }} />
         <div style={{ ...lineProps, marginBottom: '4px' }} />
         <div style={{ ...lineProps }} /> */}
      </IconButton>
   )
}

export default TOCToggleControl
